import React, { useState } from 'react';
import InputBase from '@material-ui/core/InputBase';
import {
    withStyles,
} from '@material-ui/core/styles';
import Typography from '@mui/material/Typography';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
        height: '100%',
        paddingLeft: 15,
        width: 'inherit',
    },
    input: {
        borderRadius: 4,
        width: 'inherit !important',
        position: 'relative',
        backgroundColor: '#EFEFF0',
        border: '0',
        fontSize: 16,
        height: 'inherit',
        padding: '0',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
}))(InputBase);

function TextFieldWithCounter(props) {
    const [isDirty, setIsDirty] = useState(false);
    const handleChange = (e) => {
        setIsDirty(true);
        props.onChange(e);
    }

    return (
        <div
            style={{
                height: '54px',
                background: '#EFEFF0',
                mixBlendMode: 'normal',
                // opacity: '0.6',
                borderRadius: '10px',
                display: 'flex',
                justifyContent: 'space-between',
            }}
        >
            <div
                style={{
                    width: '100%',
                }}
            >
                <BootstrapInput
                    onChange={(e) => handleChange(e)}
                    inputProps={{
                        maxLength: 50,
                    }}
                    value={props.value}
                />
                {isDirty && !props.value.length && <Typography
                    style={{
                        color: '#f44336',
                        marginTop: 2,
                        marginLeft: 4,
                    }}
                >
                    shoud be 50 chars ta least
                </Typography>}

            </div>
            {props.showCounter !== false ?
                <div
                    style={{
                        lineHeight: '50px',
                        marginRight: '3%',
                        fontWeight: 'bold',
                        color: '#878787'
                    }}
                >
                    {props.value?.length}/50
                </div>
                :
                null
            }
        </div>
    )
}

export default TextFieldWithCounter;
