import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@mui/material/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const MyButton = styled(Button)({
    height: '64px',
    background: '#F11F51',
    borderRadius: '10px',
    width: '320px',
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'none',
    textAlign: 'center',
    color: '#FFFFFF',
    '&:hover': {
        backgroundColor: '#ca0735',
    },
});

function SuccessMessage(props) {
    return (
        <div
            style={{
                textAlign: 'center'
            }}
        >
            <Typography
                style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '38px',
                    textAlign: 'center',
                    color: '#121212',
                }}
            >
                We knew it was only a matter of time.
            </Typography>
            <Typography
                style={{
                    fontFamily: 'Mulish',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '38px',
                    textAlign: 'center',
                    color: '#121212',
                }}
            >
                Well done!
            </Typography>
            <Link
                to={`/new-program`}
                component={RouterLink}
                style={{
                    textDecoration: 'none'
                }}
            >
                <MyButton>Compose next program</MyButton>
            </Link>
        </div>
    )
}

export default SuccessMessage;
