import React from 'react';
import CheckBox from './CheckBox';
import { GET } from '../helpers/DataProvider';
import { Typography } from '@material-ui/core';

class SportsList extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        sportsList: []
    }

    getData = async () => {
        const resultPrograms = await GET("sports", null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        this.setState({
            sportsList: resultPrograms
        }, () => {
            this.onChecked(resultPrograms[0])
        });
    }

    componentDidMount() {
        this.getData()
    }

    onChecked = (targetValue) => {
        this.state.sportsList.map(
            (item, index) => {
                item.id === targetValue.id ? item.checked = true : item.checked = false
            }
        )
        this.props.onChecked(this.state.sportsList, 'sportsList');
    }

    render() {
        return (
            <>
                {this.state.sportsList.map(
                    (item, index) =>
                        <CheckBox
                            key={index}
                            item={item}
                            onChecked={this.onChecked}
                        />
                )}
                <CheckBox
                    item={{
                        checked: false,
                        title: <><Typography component='span'>Yoga</Typography><span>(next)</span></>,
                    }}
                    onChecked={()=>{}}
                    style={{
                        marginRight: 15,
                    }}
                />
            </>
        );
    }
}

export default SportsList;
