import React from "react";
import Login from "../pages/Login";
import { Switch, Route, HashRouter, withRouter } from "react-router-dom";
import NewProgram from "../pages/NewProgram";
import ProgramsList from "../pages/ProgramsList";
import Finance from "../pages/Finance";
import Reports from "../pages/Reports";
import Settings from "../pages/Settings";
import SuccessfullyCreated from "../pages/SuccessfullyCreated";
import PositionedSnackbar from "../snack";
import CheckStatus from "../CheckStatus";

class Routs extends React.Component {
  render() {
    return (
      <HashRouter>
        <Switch>

          <Route
            exact
            path="/new-program"
            component={NewProgram}
          />

          <Route
            exact
            path="/programs-list"
            component={ProgramsList}
          />

          <Route
            exact
            path="/finance"
            component={Finance}
          />

          {/*<Route
            exact
            path="/positionedSnackbar"
            component={PositionedSnackbar}
          />*/}

          <Route
            exact
            path="/settings"
            component={Settings}
          />

          <Route
            exact
            path="/reports"
            component={Reports}
          />

          <Route
            exact
            path="/successfully-created"
            component={SuccessfullyCreated}
          />

          <Route
            exact
            path="/login"
            component={Login}
          />

          <Route
            exact
            path="/check-status"
            component={CheckStatus}
          />

          <Route
            path="*"
            component={CheckStatus} 
          >
            
          </Route>

        </Switch>
      </HashRouter>
    );
  }
}

export default withRouter(Routs);
