import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import Switch from '@material-ui/core/Switch';
import StatisticButton from './buttons/StatisticButton';
import EditProgramButton from '../components/buttons/EditProgramButton';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as EditIcon } from '../assets/icons/edit-2.svg';
import { ReactComponent as PiechartIcon } from '../assets/icons/pie-chart.svg';
import Hidden from '@material-ui/core/Hidden';
import Moment from 'react-moment';

const Row = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 16px #E2E7EC;
    border: 1px solid rgba(210, 210, 210, 0.200284);
    border-radius: 12px;
    margin-bottom: 20px;
    column-count: 2;
    display: flex;
    flex-wrap: wrap;
    padding : 18px;
`

const useStyles = makeStyles((theme) => ({
    bodyWeight: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        lineHeight: '29px',
        color: '#121212'
    },

    image: {
        background: 'url(Screen Shot 2020 - 12 - 04 at 4.28.20 PM.png)',
        border: '1px solid #979797',
        boxSizing: 'border-box',
        height: '174px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '260px',
        },
        backgroundSize: 'cover'
    },

    tag: {
        background: '#F11F51',
        borderRadius: '5px',
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '11px',
        lineHeight: '14px',
        color: '#FFFFFF',
        [theme.breakpoints.up('sm')]: {
            margin: '0 5px'
        },
        padding: '1px 6px',
        marginRight: 5
    },

    level: {
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        /* identical to box height */
        color: '#3B3B3D',
    },

    programDescription: {
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        /* or 136% */
        color: '#112233',
        marginBottom: '3%',
    },

    publishDate: {
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        whiteSpace: 'nowrap',
        color: '#64646C'
    },

    publishAnswer: {
        fontFamily: 'Quicksand',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '22px',
        whiteSpace: 'nowrap',
        color: '#121212',
    },

    dateContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: '8px 0px',
            justifyContent: 'space-start',
            columnGap: 20
        },
        [theme.breakpoints.up('xs')]: {
            justifyContent: 'space-between',
            columnGap: 10
        },
    },

    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            columnGap: 10
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: '253px'
        },
    },
    mobileActioncontainer: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 3
    }

}));

export const ProgramBox = (props) => {

    // return1
    const classes = useStyles();
    const editProgram = () => {
        localStorage.setItem('creationMode', 'edit');
        localStorage.setItem('programId', props.data.id);
        let tags = props.data.tags;

        // baking tags
        for (let tag of tags) {
            tag.label = tag.title;
            tag.value = tag.title;
            tag.isFixed = true;
            delete tag.__v;
            delete tag._id;
            delete tag.accountId;
            delete tag.title;
        }

        // baking sessions
        let sessions = props.data.sessions;

        for (let session of sessions) {
            session.mediaDuration = session.duration;
            session.downloadPath = session.mediaId?.downloadPath;
            session.programId = props.data.id;
            session.sessionSoundTrackDownloadPath = session.audioId?.downloadPath;
            session.soundTrackId = session.audioId?._id;
            session.mediaId = session.mediaId._id;
            session.isDeleted = false;
            for (let i = 0; i < session.techniques.length; i++) {
                session.techniques[i].width = parseInt((714 * session.techniques[i].duration) / session.duration);
                session.techniques[i].left = i === 0 ? 0 : session.techniques[i - 1].left + session.techniques[i - 1].width + 3;
                session.techniques[i].isDeleted = false;
            }
        }

        let draftProgram = {
            step: 1,
            title: props.data.title,
            description: props.data.description,
            mediaId: props.data.mediaId._id,
            programCoverDownloadPath: props.data.mediaId.downloadPath,
            sportId: props.data.sportId.id,
            levelId: {
                "title": props.data.levelTitle,
                "id": props.data.level,
                "checked": true
            },
            tags: tags,
            categoriesList: props.data.categories,
            sessions: sessions
        };

        // get list of all tags 
        localStorage.setItem('draftProgram', JSON.stringify(draftProgram));
        window.location.href = '#/new-program';
    }
    const thumbnailSrc = (props.data.mediaId && props.data.mediaId !== '') ? `${process.env.REACT_APP_FILE_URL}` + props.data.mediaId.downloadPath : "https://via.placeholder.com/260x174.png?text=smart+coach";
    return (
        <Row>
            <div
                className={classes.image}
                style={{
                    backgroundImage: 'url(' + thumbnailSrc + ')'
                }}
                title={props.data.title}
                alt={props.data.title}
            >
            </div>

            <div
                style={{
                    flex: '1 0 50%',
                    paddingLeft: '2%'
                }}
            >
                <div style={{
                    marginBottom: '3%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between'
                }}>
                    <div
                        className={classes.bodyWeight}
                    >
                        {props.data.title}
                    </div>
                    <div style={{
                        float: 'right',

                    }}>
                        <span
                            className={classes.tag}
                        >{props.data.sportId.title}</span>
                        <span
                            className={classes.level}
                        >{props.data.levelTitle}</span>
                    </div>
                </div>

                <div className={classes.programDescription}>
                    {props.data.description}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        className={classes.dateContainer}
                    >
                        <div>
                            <div className={classes.publishDate}>Publish date</div>
                            <div className={classes.publishAnswer}>
                                <Moment
                                    fromNow
                                >
                                    {props.data.publishDate}
                                </Moment>
                            </div>
                        </div>

                        <div>
                            <div className={classes.publishDate}>Status</div>
                            <div className={classes.publishAnswer}>{props.data.statusTitle}</div>
                        </div>

                        <div
                            style={{
                                height: 30
                            }}
                        >
                            <Switch
                                checked={true}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </div>

                    <Hidden only={['lg', 'xl', 'xs']}>
                        <div
                            className={classes.mobileActioncontainer}
                        >
                            <IconButton
                                aria-label="delete"
                                // className={classes.margin}
                                variant="contained"
                                style={{
                                    backgroundColor: 'aliceblue'
                                }}
                            >
                                <PiechartIcon
                                    fontSize="large"
                                    style={{
                                        color: "#182A7D"
                                    }}
                                />
                            </IconButton>

                            <IconButton
                                aria-label="delete"
                                // className={classes.margin}
                                style={{
                                    backgroundColor: 'aliceblue'
                                }}
                            >
                                <EditIcon
                                    fontSize="large"
                                    style={{
                                        color: "#182A7D"
                                    }}
                                />
                            </IconButton>
                        </div>
                    </Hidden>

                    <Hidden only={['md', 'sm']}>
                        <div
                            className={classes.actionContainer}
                        >
                            <StatisticButton />
                            <EditProgramButton
                                onClick={editProgram}
                            />
                        </div>
                    </Hidden>

                </div>

            </div>
        </Row>
    )
}