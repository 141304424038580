import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as RemoveIcon } from '../assets/icons/x.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Timeline } from './Timeline';
import SegmentLine from './SegmentLine';
import { GeneralFunctions } from '../helpers/GeneralFunctions';

const useStyles = makeStyles({
    player: {
        border: '1px dashed #D0D5DA',
        borderRadius: 6,
    },
    // timeLine: {
    //     position: 'relative',
    //     width: 706,
    //     height: 24,
    //     borderRadius: 6,
    //     backgroundColor: '#bdbdbd',
    //     cursor: "url('http://www.gravatar.com/avatar/06ededb95f1c0b1f4f9d987e06d7d82b?s=32&d=identicon&r=PG'), pointer"
    // },
    contextMenuItem: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontSize: 16,
        color: '#FFFFFF',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 900,
        }
    },
    playButton: {
        width: 110,
        height: 110,
        background: 'rgba(235, 83, 83, 0.8)',
        borderRadius: '50%',
        mixBlendMode: 'normal',
        opacity: 0.8,
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer',
        '&:hover': {
            background: 'rgba(190, 63, 63, 0.8)',
        }
    },
    playIcon: {
        width: 40,
        height: 40,
        borderRadius: '50%',
        mixBlendMode: 'normal',
        margin: 0,
        position: 'absolute',
        top: '52%',
        left: '52%',
        color: 'white',
        transform: 'translate(-50%, -50%)',
        cursor: 'pointer'
    }
});

export const VideoEditor = (props) => {
    const classes = useStyles();

    const [left, setLeft] = useState(0);
    const [dimensionFromLeft, setDimensionFromLeft] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [width, setwidth] = useState(0);
    const [videoLength] = useState(1220);
    const myRef = React.createRef();
    const videoRef = React.createRef();
    const timeLineRef = React.createRef();

    const addSegment = (segment) => {
        props.addTechniquBar(segment);
    }

    const timeUpdate = (event) => {
        setLeft((parseInt(event.target.currentTime) * width) / videoLength);
        setCurrentTime(secondsToHumanReadable(parseInt(event.target.currentTime)));
    }

    const secondsToHumanReadable = (time) => {
        let hours = Math.floor(time / 3600);
        let minutes = Math.floor((time - (hours * 3600)) / 60);
        let seconds = time - ((hours * 3600) + (minutes * 60));
        return hours + ':' + minutes + ':' + seconds;
    }

    return (
        <div
            style={{
                position: 'relative'
            }}
        >

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: 70,
                    alignItems: 'center'
                }}
            >
                <Typography
                    style={{
                        fontFamily: 'Mulish',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 20,
                        color: '#121212',
                    }}
                >Session main content</Typography>
                <div
                    style={{
                        fontFamily: 'Mulish',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: 16,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                    onClick={() => props.removeSessionVideo()}

                >Remove <RemoveIcon /></div>
            </div>

            <div className={classes.player}>
                <video
                    onTimeUpdate={(e) => timeUpdate(e)}
                    controls
                    ref={videoRef}
                    width="100%"
                    height="100%"
                    onLoadedMetadata={(e) => props.setVideoProperties(e)}
                >
                    <source
                        src={`${process.env.REACT_APP_FILE_URL}/` + props.downloadPath}
                        type="video/mp4"
                    />
                    Your browser does not support the video tag.
                </video>

            </div>

            <br />

            <div
                style={{
                    width: '100%',
                    height: 20,
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Typography
                    style={{
                        fontFamily: 'Mulish',
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: '#000000'
                    }}
                >
                    {currentTime}
                </Typography>
                <Typography
                    style={{
                        fontFamily: 'Mulish',
                        fontWeight: 'bold',
                        fontSize: 12,
                        color: '#000000',
                    }}
                >
                    {GeneralFunctions.convertSecondsToHumanReadable(props.duration)}
                </Typography>
            </div>

            <div
                style={{
                    height: 86,
                    background: 'rgba(24, 42, 125, 0.5)',
                    borderRadius: 6,
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    position: 'relative',
                    justifyContent: 'space-evenly'
                }}
                ref={timeLineRef}
            >
                <Timeline />
                <SegmentLine
                    segments={props.segments}
                    addSegment={addSegment}
                    mediaDuration={props.duration}
                    reArrangeDurations={(newSegments) => props.reArrangeDurations(newSegments)}
                    sessionIndex={props.sessionIndex}
                />
            </div>

        </div >
    )
}