import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const MyButton = styled(Button)({
    border: '2px solid #182A7D',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '130px',
    height: '64px',
    textTransform: 'none',
    color: '#182A7D',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: '18px'
});

interface IDiscardButton {
    discardProgram: () => void,
    children: ReactNode
}

const DiscardButton: React.FC<IDiscardButton> = ({ discardProgram, children }) => {
    const [open, setOpen] = React.useState(false);

    const discard = () => {
        discardProgram();
        setOpen(false);
    }

    const showDiscardDialog = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>

            <MyButton
                variant="outlined"
                color="primary"
                onClick={() => showDiscardDialog()}
            >
                {children}
            </MyButton>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Discard create program?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="primary"
                    >
                        Disagree
                    </Button>
                    <Button
                        onClick={() => discard()}
                        color="primary"
                    >
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DiscardButton;