import React from 'react';
import { GeneralFunctions } from './helpers/GeneralFunctions';
import store from './redux/store';
import { setUserinfo, setUserLogout } from './redux/actions';
import { GET } from "./helpers/DataProvider";

class CheckStatus extends React.Component {

    constructor(props) {
        super(props);
    }

    defineStatus = async () => {
        let status = await GeneralFunctions.IsTokenActive();
        if (status === false) {
            store.dispatch(setUserLogout());
            window.location.href = '#/login'
        } else {
            let userFullInformation = await GET('profile', null, { 'X-ACCESS-TOKEN': GeneralFunctions.getCurrentToken() })
            let userData = {
                isLogin: true,
                token: GeneralFunctions.getCurrentToken(),
                userFullInformation: userFullInformation[0]
            }
            store.dispatch(setUserinfo(userData));
            window.location.href = `${window.localStorage.getItem('last-location')}`
        }
    }

    componentDidMount = (props) => {
        this.defineStatus();
    }

    render() {
        return (
            <div>initializing...</div>
        )
    }

}

export default CheckStatus;
