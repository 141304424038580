import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    myBtn: {
        border: '1px solid #182A7D',
        boxSizing: 'border-box',
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 122
        },
        height: '44px',
        textTransform: 'none',
        color: '#182A7D',
        fontFamily: 'Mulish',
        fontWeight: 'bold',
        fontSize: '18px'
    }
}));

interface IStatisticButton {
}

const StatisticButton: React.FC<IStatisticButton> = () => {
    const classes = useStyles();
    return (
        <Button className={classes.myBtn} variant="outlined" color="primary">
            Statistics
        </Button>
    )
}

export default StatisticButton;