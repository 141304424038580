import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const MyButton = styled(Button)({
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: 10,
    color: 'white',
    height: 75,
    padding: '0 30px',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Mulish',
    fontWeight: '700',
    width: 283,
    margin: '8px 0',
    textTransform: 'none',
    justifyContent: 'left',
    '&:hover': {
        backgroundColor: '#E0F1FF',
        color:'#182A7D'
    },
});

function MobileSideNavButton(props) {
    return (
        <Link
            to={props.href}
            component={RouterLink}
            style={{
                textDecoration: 'none'
            }}
        >
            <MyButton
                onClick={event => props.toggleDrawer(event, 'left', true)}
                startIcon={props.icon}
            >
                <span
                    style={{
                        paddingLeft: '5%'
                    }}
                >{props.children}</span>
            </MyButton>
        </Link>
    )
}

export default MobileSideNavButton;