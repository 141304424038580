import Button from '@material-ui/core/Button';
import React, { ReactNode } from 'react';
import { styled } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

const MyButton = styled(Button)({
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '130px',
    height: '64px',
    textTransform: 'none',
    backgroundColor: '#182A7D',
    color: 'white',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: '18px',
    '&:hover': {
        backgroundColor: '#E0F1FF !important',
        color: '#182A7D !important'
    }
});

const useStyles = makeStyles(theme => ({
    btn: {
        boxSizing: 'border-box',
        borderRadius: '10px',
        width: '130px',
        height: '64px',
        textTransform: 'none',
        backgroundColor: '#182A7D',
        color: 'white',
        fontFamily: 'Mulish',
        fontWeight: 'bold',
        fontSize: '18px',
        '&:hover': {
            backgroundColor: '#E0F1FF !important',
            color: '#182A7D !important'
        }
    },
    disabled: {
        backgroundColor: '#182a7d42'
    }

}));

interface INextButton {
    onClick: () => void,
    disabled: boolean,
    children: ReactNode
}

const NextButton: React.FC<INextButton> = ({ onClick, disabled, children }) => {
    const classes = useStyles();
    const doNext = () => {
        onClick()
    }

    return (
        <Button
            variant="outlined"
            color="primary"
            onClick={() => { doNext() }}
            // className={classes.btn}
            className={`${classes.btn} ${disabled ? classes.disabled : ""}`}
            disabled={disabled}
            style={{
                color: 'white'
            }}
        >
            {children}
        </Button>
    )
}

export default NextButton;
