import React, { useState, useEffect } from 'react';
import { ReactComponent as Checkicon } from '../assets/icons/check.svg';

function CheckBox(props) {
    const [value, setValue] = useState(props.item);
    const setCheckboxValue = (value) => {
        props.onChecked(value);
    }

    return (
        
        <div
            style={{
                width: 156,
                height: 44,
                background: props.item.checked ? '#E0F1FF' : 'unset',
                border: '1px solid rgba(24, 42, 125, 0.4)',
                boxSizing: 'border-box',
                borderRadius: '8px',
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 10,
                color: props.item.checked ? 'black' : '#c1c1c1'
            }}
            onClick={() => setCheckboxValue(value)}
        >
            
            <div
                style={{
                    height: '60%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '80%',
                    alignItems: 'center'
                }}
            >

                <div
                    style={{
                        fontFamily: 'Mulish',
                        fontSize: '16px'
                    }}
                >
                    {value.title}
                </div>
                {value.checked ?
                    <div
                        style={{
                            backgroundColor: '#182A7D',
                            minWidth: 24,
                            minHeight: 24,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Checkicon
                            style={{
                                color: 'white',
                                width: '20px',
                                height: '20px'
                            }}
                        />
                    </div>
                    :
                    <div
                        style={{
                            minWidth: 18,
                            minHeight: 18,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            border: '4px solid #D7DEE4'
                        }}
                    >
                    </div>
                }
            </div>
        </div >
    )
}

export default CheckBox;
