import React, { useState, useEffect } from 'react';
import TextFieldWithCounter from '../components/TextFieldWithCounter';
import UploadBox from '../components/UploadBox';
import Tags from '../components/Tags';
import Categories from '../components/Categories';

import AddSession from '../AddSession';

import { Typography } from '@material-ui/core';
import DiscardButton from '../components/buttons/DiscardButton';
import MobileDiscardButton from '../components/buttons/MobileDiscardButton';
import NextButton from '../components/buttons/NextButton';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import DescriptionTextfiled from '../components/DescriptionTextfiled';
import Hidden from '@material-ui/core/Hidden';
import Switch from '@material-ui/core/Switch';
import MobileNextButton from '../components/buttons/MobileNextButton';

import SportsList from '../components/SportsList';
import CategoriesList from '../components/CategoriesList';
import LevelsList from '../components/LevelsList';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';

import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Session from '../session';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Category } from '@material-ui/icons';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}



const SortableContainer = sortableContainer(({ children }) => {
    return <ul style={{ padding: 0 }}>{children}</ul>;
});



function TabPanel({ history }) {
    if (!localStorage.getItem('creationMode')) {
        localStorage.setItem('creationMode', 'create');
    }
    if (!localStorage.getItem('draftProgram')) {
        let draftProgram = {
            step: 1,
            title: '',
            description: '',
            mediaId: null,
            programCoverDownloadPath: null,
            sportId: [],
            levelId: null,
            tags: [],
            categoriesList: [],
            sessions: [{
                mediaId: '',
                isCollapse: false,
                sessionVideoDownloadPath: null,
                soundTrackId: null,
                sessionSoundTrackDownloadPath: null,
                sessionCoverDownloadPath: null,
                programId: '',
                title: '',
                sessionNo: 0,
                description: '',
                techniques: [],
                mediaDuration: 0,
                tmppath: null,
                isDeleted: false,
                calorie: 0
            }]
        }
        localStorage.setItem('draftProgram', JSON.stringify(draftProgram));
    }
    const [step, setStep] = useState(JSON.parse(localStorage.getItem('draftProgram')).step);
    const [title, setTitle] = useState(JSON.parse(localStorage.getItem('draftProgram')).title);
    const [description, setDescription] = useState(JSON.parse(localStorage.getItem('draftProgram')).description);
    const [mediaId, setMediaId] = useState(JSON.parse(localStorage.getItem('draftProgram')).mediaId);
    const [programCoverDownloadPath, setProgramCoverDownloadPath] = useState(JSON.parse(localStorage.getItem('draftProgram')).programCoverDownloadPath);
    const [sportId, setSportId] = useState(null);
    const [levelId, setLevelId] = useState(JSON.parse(localStorage.getItem('draftProgram')).levelId || {
        "title": "Beginner",
        "id": 0,
        "checked": true
    });
    const [tags, setTags] = useState(JSON.parse(localStorage.getItem('draftProgram')).tags || []);
    const [categoriesList, setCategoriesList] = useState(JSON.parse(localStorage.getItem('draftProgram')).categoriesList || []);
    const [leftOfTabPanelIndexer, setLeftOfTabPanelIndexer] = useState('0');
    const [sessions, setSession] = useState(JSON.parse(localStorage.getItem('draftProgram')).sessions);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [deleteSessionDialog, setDeleteSessionDialog] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);

    const onSortEnd = (params) => {
        let tempSessions = sessions;
        let temp = sessions[params.oldIndex];
        tempSessions.splice(params.oldIndex, 1);
        tempSessions.splice(params.newIndex, 0, temp);
        let final = [];
        final = tempSessions.map(item => item);
        setSession(final);
    };

    useEffect(() => {
        let draftProgram = {
            step: step,
            title: title,
            description: description,
            mediaId: mediaId,
            programCoverDownloadPath: programCoverDownloadPath,
            sportId: sportId,
            levelId: levelId,
            tags: tags,
            categoriesList: categoriesList,
            sessions: sessions
        }
        localStorage.setItem('draftProgram', JSON.stringify(draftProgram));
        // handle steps
        switch (step) {
            case 1:
                setLeftOfTabPanelIndexer('0%');
                break;

            case 2:
                setLeftOfTabPanelIndexer('35%');
                break;

            case 3:
                setLeftOfTabPanelIndexer('70%');
                break;

            default:
        }
    });

    const resetState = () => {
        setStep(1);
        setTitle('');
        setDescription('');
        setMediaId(null);
        setProgramCoverDownloadPath(null);
        setSportId(null);
        setLevelId(null);
        setTags([]);
        setCategoriesList([]);
        setLeftOfTabPanelIndexer('0');
        setSession([{
            isDeleted: false,
            mediaId: '',
            sessionVideoDownloadPath: null,
            soundTrackId: null,
            sessionSoundTrackDownloadPath: null,
            sessionCoverDownloadPath: null,
            programId: '',
            title: '',
            sessionNo: 0,
            description: '',
            techniques: [],
            mediaDuration: 0,
            tmppath: null,
            calorie: 0
        }]);

    }

    const setSessionTitle = (value, index) => {
        let tempSession = sessions;
        tempSession[index].title = value;
        setSession([...tempSession]);
    }

    const setSessionCalories = (value, index) => {
        let tempSession = sessions;
        tempSession[index].calorie = parseInt(value);
        setSession([...tempSession]);
    }

    const addTechniqueName = (value, techniqueIndex, sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].techniques[techniqueIndex].title = value;
        setSession([...tempSession]);
    }

    const checkIfIncreaseTechniqueGranted = (duration, techniqueIndex, sessionIndex) => {
        let allDurationInSeconds = 0;
        let sessionVideoLengthInSeconds = parseInt(sessions[sessionIndex].mediaDuration);
        let tempTechniques = sessions[sessionIndex].techniques;
        tempTechniques[techniqueIndex].duration = duration;

        for (let i = 0; i < tempTechniques.length; i++) {
            allDurationInSeconds += parseInt(tempTechniques[i].duration);
        }

        if (allDurationInSeconds > sessionVideoLengthInSeconds)
            return false;
        return true;
    }

    const updateDuration = (newduration, techniqueIndex, sessionIndex) => {
        let tempSession = sessions;
        if (checkIfIncreaseTechniqueGranted(newduration, techniqueIndex, sessionIndex)) {
            tempSession[sessionIndex].techniques[techniqueIndex].duration = (parseInt(newduration) < 2 ? 1 : newduration);
            defragmentTechniques(sessionIndex);
            setSession([...tempSession]);
        }
    }

    const addTechniqueRepetitionCount = (val, techniqueIndex, sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].techniques[techniqueIndex].repetitionCount = val;
        setSession([...tempSession]);
    }

    const toggleHasMusic = (val, techniqueIndex, sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].techniques[techniqueIndex].isMusicOn = val;
        setSession([...tempSession]);
    }

    const getCurrentIndexEndSecond = (sessionIndex, techniqueIndex) => {
        let tempSession = sessions[sessionIndex];
        let summaryOfEndSeconds = 0;
        for (let i = 0; i <= techniqueIndex; i++) {
            summaryOfEndSeconds = parseInt(summaryOfEndSeconds + parseInt(tempSession.techniques[i].duration))
        }
        return summaryOfEndSeconds;
    }

    const defragmentTechniques = (sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].techniques[0].startSecond = 0;
        tempSession[sessionIndex].techniques[0].endSecond = tempSession[sessionIndex].techniques[0].isDeleted ? tempSession[sessionIndex].techniques[0].startSecond : parseInt(tempSession[sessionIndex].techniques[0].duration);
        if (tempSession[sessionIndex].techniques[0].isDeleted) {
            tempSession[sessionIndex].techniques[0].duration = 0;
        }
        for (let i = 1; i < tempSession[sessionIndex].techniques.length; i++) {
            if (tempSession[sessionIndex].techniques[i].isDeleted) {
                tempSession[sessionIndex].techniques[i].endSecond = tempSession[sessionIndex].techniques[i].startSecond;
                tempSession[sessionIndex].techniques[i].duration = 0;
            } else {
                tempSession[sessionIndex].techniques[i].startSecond = parseInt(parseInt(tempSession[sessionIndex].techniques[i - 1].duration) + tempSession[sessionIndex].techniques[i - 1].startSecond);
                tempSession[sessionIndex].techniques[i].endSecond = parseInt(getCurrentIndexEndSecond(sessionIndex, i));
            }

        }
        setSession([...tempSession]);
    }

    const removeTechnique = (techniqueIndex, sessionIndex) => {
        let tempSession = sessions;

        // old school : physicaly remove a technique
        tempSession[sessionIndex].techniques.splice(techniqueIndex, 1);

        //new school : logically remove a technique
        // tempSession[sessionIndex].techniques[techniqueIndex].isDeleted = true;

        if (tempSession[sessionIndex].techniques.length)
            defragmentTechniques(sessionIndex);
        setSession([...tempSession]);
    }

    const addNewSession = () => {
        let tempSessionObject = {
            mediaId: '',
            isCollapse: false,
            sessionVideoDownloadPath: null,
            soundTrackId: null,
            sessionSoundTrackDownloadPath: null,
            sessionCoverDownloadPath: null,
            programId: '',
            title: '',
            sessionNo: 0,
            description: '',
            techniques: [],
            mediaDuration: 0,
            tmppath: null,
            isDeleted: false
        }

        let tempSession = sessions;
        tempSessionObject.sessionNo = sessions.length;
        tempSession.push(tempSessionObject);
        setSession([...tempSession]);
    }

    const setSessionDescription = (value, index) => {
        let tempSession = sessions;
        tempSession[index].description = value;
        setSession([...tempSession]);
    }

    const sumOfAllDurations = (sessionIndex) => {
        let startSecond = 0;
        sessions[sessionIndex].techniques.map(
            (technique, index) => {
                startSecond += technique.duration;
            }
        )
        return startSecond;
    }

    const closeDeleteDialog = () => {
        setDeleteSessionDialog(false);
    }

    const removeSessionVideo = (sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].mediaId = '';
        tempSession[sessionIndex].mediaDuration = 0;
        tempSession[sessionIndex].techniques = [];
        setSession([...tempSession]);
    }

    const collapseSession = (event, sessionIndex) => {
        event.stopPropagation();
        event.preventDefault();
        let tempSession = sessions;
        tempSession[sessionIndex].isCollapse = !tempSession[sessionIndex].isCollapse;
        setSession([...tempSession]);
    }

    const collapseSessions = () => {
        let tempSessions = sessions;
        tempSessions.forEach(session => session.isCollapse = false);
        setSession([...tempSessions]);
    }

    const expandSessions = () => {
        let tempSessions = sessions;
        tempSessions.forEach(session => session.isCollapse = true);
        setSession([...tempSessions]);
    }

    const discardProgram = () => {
        localStorage.removeItem('draftProgram');
        localStorage.removeItem('programId');
        localStorage.setItem('creationMode', 'create');
        resetState();
        //window.location.href = "#/programs-list";
    }

    const openDeleteSessionDialog = (index) => {
        setDeleteSessionDialog(true);
        setSelectedSession(index);
    }

    const backToStep = () => {
        let currentStep = step;
        switch (currentStep) {

            case 2:
                setLeftOfTabPanelIndexer('0%');
                setStep(currentStep - 1);
                break;

            case 3:
                setLeftOfTabPanelIndexer('35%');
                setStep(currentStep - 1);
                break;

            default:
        }
    }

    const setVideoProperties = (e, sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].mediaDuration = e.target.duration;
        setSession([...tempSession]);
    }

    const reArrangeDurations = (newSegments, sessionIndex) => {
        let tempSession = sessions;
        tempSession[sessionIndex].techniques = newSegments;
        setSession([...tempSession]);
    }

    const canAddTechnique = (segment, sessionIndex) => {
        let sum = 0;
        for (let technique of sessions[sessionIndex].techniques) {
            sum += technique.duration
        };
        if (
            (sum + segment.duration)
            // +
            // (sessions[sessionIndex].techniques.length * 3)
            >
            sessions[sessionIndex].mediaDuration) {
            setShowSnackbar(true);
            return false
        }
        return true;
    }

    const calculateSegmentDuration = (sessionIndex) => {
        let sum = 0;
        for (let technique of sessions[sessionIndex].techniques) {
            sum += technique.duration
        };
        if (
            (sessions[sessionIndex].mediaDuration - sum)
            <
            (Math.floor(sessions[sessionIndex].mediaDuration / 12))
        ) {
            return 2
        } else {

            return Math.floor(sessions[sessionIndex].mediaDuration / 12);      // in seconds
        }
    }

    const addTechniquBar = (segment, sessionIndex) => {
        // segment.duration = calculateSegmentDuration(sessionIndex);
        if (canAddTechnique(segment, sessionIndex)) {
            // add to segments
            let tempSession = sessions;
            let tempTechniqueObject = {};
            tempTechniqueObject.startSecond = sessions[sessionIndex] === 0 ? 0 : sumOfAllDurations(sessionIndex);
            tempTechniqueObject.duration = calculateSegmentDuration(sessionIndex);
            tempTechniqueObject.endSecond = tempTechniqueObject.startSecond + tempTechniqueObject.duration;
            tempTechniqueObject.title = segment.label;
            tempTechniqueObject.description = 'technique description';
            if (segment.type == 'workout') {
                tempTechniqueObject.repetitionCount = 1;
                tempTechniqueObject.isMusicOn = true;
            }
            tempTechniqueObject.type = segment.typeNumber;
            tempTechniqueObject.typeLabel = segment.type;
            tempTechniqueObject.isDeleted = null;
            tempSession[sessionIndex].techniques.push(tempTechniqueObject);
            setSession([...tempSession]);
        }
    }

    const uploadProgramCover = (uploadResult) => {
        setMediaId(uploadResult[0].id);
        setProgramCoverDownloadPath(uploadResult[0].downloadPath);
    }

    const removeProgramCover = () => {
        setMediaId(null);
        setProgramCoverDownloadPath(null);
    }

    const uploadSessionVideo = (uploadResult, index) => {
        let tempSessions = sessions;
        tempSessions[index].mediaId = uploadResult[0].id;
        tempSessions[index].sessionVideoDownloadPath = uploadResult[0].id;
        tempSessions[index].downloadPath = uploadResult[0].downloadPath;
        setSession([...tempSessions]);
    }

    const uploadSessionCover = (uploadResult, index) => {
        console.info('uploadResult:', uploadResult);
        console.info('index:', index);
        let tempSessions = sessions;
        tempSessions[index].coverId = uploadResult[0].id;
        tempSessions[index].coverDownloadPath = uploadResult[0].downloadPath;
        setSession([...tempSessions]);
    }

    const uploadSessoinSoundtrack = (uploadResult, index) => {
        let tempSessions = sessions;
        tempSessions[index].soundTrackId = uploadResult[0].id;
        tempSessions[index].sessionSoundTrackDownloadPath = uploadResult[0].downloadPath;
        setSession([...tempSessions]);
    }

    const removeSessionSoundTrack = (index) => {
        let tempSessions = sessions;
        tempSessions[index].soundTrackId = null;
        tempSessions[index].sessionSoundTrackDownloadPath = null;
        setSession([...tempSessions]);
    }

    const removeSessionCover = (index) => {
        let tempSessions = sessions;
        tempSessions[index].coverId = null;
        tempSessions[index].sessionCoverDownloadPath = null;
        setSession([...tempSessions]);
    }

    const onSportChecked = (sportUpdatedList) => {
        setSportId(sportUpdatedList[0]._id);
    }

    const onLevelChecked = (levelUpdatedList) => {
        setLevelId(levelUpdatedList);
    }

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const createProgram = async () => {

        let creationMode = localStorage.getItem('creationMode');
        let formData = new FormData();
        formData.append('title', title.trim());
        formData.append('description', description.trim());
        formData.append('mediaId', mediaId);
        formData.append('sportId', sportId);
        formData.append('level', levelId.id);
        formData.append('price', 0);
        formData.append('startDate', '2021-05-22');
        formData.append('endDate', '2021-07-30');
        formData.append('status', 0);

        let tempArr = [];
        tags.map((tag) => tempArr.push(tag.id));
        formData.append('tags', JSON.stringify(tempArr));

        let tempArr2 = [];
        if (typeof categoriesList[0] === 'object')
            categoriesList.map((category) => tempArr2.push(category.id));

        else
            categoriesList.map((category) => tempArr2.push(category));

        formData.append('categories', JSON.stringify(tempArr2));

        // get Programs
        let programUrl;
        let programMethod;
        if (creationMode === 'create') {
            programUrl = `${process.env.REACT_APP_API_URL}coach/programs/build`;
            programMethod = 'POST';
        } else {
            programUrl = `${process.env.REACT_APP_API_URL}coach/programs/${localStorage.getItem('programId')}`;
            programMethod = 'PUT';
        }
        fetch(programUrl, {
            method: programMethod, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
            },
            // redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
        })
            .then(result => {
                if (result.statusText === 'OK') {
                    return {
                        "data": [
                            "61c0affdc91d070034164728"
                        ],
                        "totalCount": 1
                    }
                } else {
                    return result.json()
                }
            })
            .then(programsResult => {

                // let pId = localStorage.getItem('programId') ? localStorage.getItem('programId') : ;
                for (let sess of sessions) {
                    // iterate over sessions array
                    let sessionFormData = new FormData();
                    sessionFormData.append('mediaId', sess.mediaId);
                    sessionFormData.append('title', sess.title.trim());
                    sessionFormData.append('sessionNo', sess.sessionNo);
                    sessionFormData.append('calorie', sess.calorie);
                    sessionFormData.append('description', sess.description.trim());
                    sessionFormData.append('coverId', sess.coverId);
                    if (sess.soundTrackId)
                        sessionFormData.append('audioId', sess.soundTrackId);

                    //          START create session
                    let sessionUrl;
                    let sessionMethod;

                    if (creationMode === 'create') {
                        sessionFormData.append('programId', programsResult.data[0]);
                        sessionUrl = `${process.env.REACT_APP_API_URL}coach/programs/sessions`;
                        sessionMethod = 'POST';
                        fetch(sessionUrl, {
                            method: sessionMethod, // *GET, POST, PUT, DELETE, etc.
                            mode: 'cors', // no-cors, cors, *same-origin
                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                            // credentials: 'same-origin', // include, *same-origin, omit
                            headers: {
                                'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                            },
                            // redirect: 'follow', // manual, *follow, error
                            referrer: 'no-referrer', // no-referrer, *client
                            body: sessionFormData, // body data type must match "Content-Type" header
                        })
                            .then(result => {
                                if (result.statusText === 'OK') {
                                    return {
                                        "data": [
                                            "61c0affdc91d070034164728"
                                        ],
                                        "totalCount": 1
                                    }
                                } else {
                                    return result.json()
                                }
                            })
                            .then(async (sessionResult) => {
                                // let sId = localStorage.getItem('creationMode') === 'create' ? sessionResult.data[0] : sess._id;
                                for (let tech of sess.techniques) {
                                    //  iterate over technique array
                                    let techniqueFormData = new FormData();
                                    techniqueFormData.append('startSecond', tech.startSecond);
                                    techniqueFormData.append('endSecond', tech.endSecond);
                                    techniqueFormData.append('sessionId', sessionResult.data[0].id);
                                    techniqueFormData.append('title', tech.title.trim());
                                    techniqueFormData.append('type', tech.type);
                                    if (tech.type == 0 || tech.type == 1) {
                                        techniqueFormData.append('repetitionCount', 1);
                                        techniqueFormData.append('isMusicOn', 0);
                                    }
                                    if (tech.type == 2) {
                                        techniqueFormData.append('repetitionCount', tech.repetitionCount);
                                        techniqueFormData.append('isMusicOn', +tech.isMusicOn);
                                    }
                                    techniqueFormData.append('description', tech.description.trim());
                                    techniqueFormData.append('tags', '[\"60a587acee4b8f168f98679a\"]');

                                    let techniqueUrl;
                                    let techniqueMethod;
                                    techniqueUrl = `${process.env.REACT_APP_API_URL}coach/programs/techniques`;
                                    techniqueMethod = 'POST';
                                    if (!tech.isDeleted) {
                                        fetch(techniqueUrl, {
                                            method: techniqueMethod, // *GET, POST, PUT, DELETE, etc.
                                            mode: 'cors', // no-cors, cors, *same-origin
                                            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                            // credentials: 'same-origin', // include, *same-origin, omit
                                            headers: {
                                                'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                            },
                                            // redirect: 'follow', // manual, *follow, error
                                            referrer: 'no-referrer', // no-referrer, *client
                                            body: techniqueFormData
                                        })
                                    }
                                }
                            })
                            .then(() => {
                                localStorage.removeItem('draftProgram');
                                localStorage.setItem('creationMode', 'create');
                                resetState();
                                window.location.href = "#/successfully-created";
                            });

                    } else {
                        sessionFormData.append('programId', localStorage.getItem('programId'));
                        sessionUrl = `${process.env.REACT_APP_API_URL}coach/programs/sessions/${sess._id}`;
                        sessionMethod = 'PUT';
                        if (sess.isDeleted === false) {
                            fetch(sessionUrl, {
                                method: sessionMethod, // *GET, POST, PUT, DELETE, etc.
                                mode: 'cors', // no-cors, cors, *same-origin
                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                // credentials: 'same-origin', // include, *same-origin, omit
                                headers: {
                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                },
                                // redirect: 'follow', // manual, *follow, error
                                referrer: 'no-referrer', // no-referrer, *client
                                body: sessionFormData, // body data type must match "Content-Type" header
                            })
                                .then(result => {
                                    if (result.statusText === 'OK') {
                                        return {
                                            "data": [
                                                "61c0affdc91d070034164728"
                                            ],
                                            "totalCount": 1
                                        }
                                    } else {
                                        return result.json()
                                    }
                                })
                                .then(async (sessionResult) => {
                                    for (let tech of sess.techniques) {

                                        //  iterate over technique array
                                        let techniqueFormData = new FormData();
                                        techniqueFormData.append('startSecond', tech.startSecond);
                                        techniqueFormData.append('endSecond', tech.endSecond);
                                        techniqueFormData.append('sessionId', sess._id);
                                        techniqueFormData.append('title', tech.title);
                                        techniqueFormData.append('type', tech.type);
                                        techniqueFormData.append('repetitionCount', tech.repetitionCount);
                                        techniqueFormData.append('description', tech.description);
                                        techniqueFormData.append('tags', '[\"60a587acee4b8f168f98679a\"]');

                                        let techniqueUrl;
                                        let techniqueMethod;
                                        if (tech.isDeleted === null) {
                                            techniqueUrl = `${process.env.REACT_APP_API_URL}coach/programs/techniques`;
                                            techniqueMethod = 'POST';
                                            if (!tech.isDeleted) {
                                                fetch(techniqueUrl, {
                                                    method: techniqueMethod, // *GET, POST, PUT, DELETE, etc.
                                                    mode: 'cors', // no-cors, cors, *same-origin
                                                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                                    // credentials: 'same-origin', // include, *same-origin, omit
                                                    headers: {
                                                        'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                                    },
                                                    // redirect: 'follow', // manual, *follow, error
                                                    referrer: 'no-referrer', // no-referrer, *client
                                                    body: techniqueFormData
                                                })
                                            }
                                        }

                                        // edit mode
                                        else if (tech.isDeleted === false) {
                                            techniqueUrl = `${process.env.REACT_APP_API_URL}coach/programs/techniques/${tech._id}`;

                                            fetch(techniqueUrl, {
                                                method: 'PUT', // *GET, POST, PUT, DELETE, etc.
                                                mode: 'cors', // no-cors, cors, *same-origin
                                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                                // credentials: 'same-origin', // include, *same-origin, omit
                                                headers: {
                                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                                },
                                                // redirect: 'follow', // manual, *follow, error
                                                referrer: 'no-referrer', // no-referrer, *client
                                                body: techniqueFormData
                                            });
                                        } else if (tech.isDeleted === true) {
                                            techniqueUrl = `${process.env.REACT_APP_API_URL}coach/programs/techniques/${tech._id}`;
                                            fetch(techniqueUrl, {
                                                method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                                                mode: 'cors', // no-cors, cors, *same-origin
                                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                                // credentials: 'same-origin', // include, *same-origin, omit
                                                headers: {
                                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                                },
                                                // redirect: 'follow', // manual, *follow, error
                                                referrer: 'no-referrer', // no-referrer, *client
                                            })
                                        }
                                    }
                                    //          END create technique
                                })
                                .then(() => {
                                    localStorage.removeItem('draftProgram');
                                    localStorage.setItem('creationMode', 'create');
                                    resetState();
                                });
                        } else if (sess.isDeleted === true) {
                            fetch(sessionUrl, {
                                method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                                mode: 'cors', // no-cors, cors, *same-origin
                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                // credentials: 'same-origin', // include, *same-origin, omit
                                headers: {
                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                },
                                // redirect: 'follow', // manual, *follow, error
                                referrer: 'no-referrer', // no-referrer, *client
                                // body: sessionFormData, // body data type must match "Content-Type" header
                            })
                        } else if (sess.isDeleted === null) {
                            sessionUrl = `${process.env.REACT_APP_API_URL}coach/programs/sessions`;
                            fetch(sessionUrl, {
                                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                                mode: 'cors', // no-cors, cors, *same-origin
                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                // credentials: 'same-origin', // include, *same-origin, omit
                                headers: {
                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                },
                                // redirect: 'follow', // manual, *follow, error
                                referrer: 'no-referrer', // no-referrer, *client
                                body: sessionFormData, // body data type must match "Content-Type" header
                            })
                                .then(result => {
                                    if (result.statusText === 'OK') {
                                        return {
                                            "data": [
                                                "61c0affdc91d070034164728"
                                            ],
                                            "totalCount": 1
                                        }
                                    } else {
                                        return result.json()
                                    }
                                })
                                .then(async (sessionResult) => {
                                    for (let tech of sess.techniques) {
                                        //  iterate over technique array
                                        let techniqueFormData = new FormData();
                                        techniqueFormData.append('startSecond', tech.startSecond);
                                        techniqueFormData.append('endSecond', tech.endSecond);
                                        techniqueFormData.append('sessionId', sessionResult.data[0]);
                                        techniqueFormData.append('title', tech.title);
                                        techniqueFormData.append('type', tech.type);
                                        techniqueFormData.append('repetitionCount', tech.repetitionCount);
                                        techniqueFormData.append('description', tech.description);
                                        techniqueFormData.append('tags', '[\"60a587acee4b8f168f98679a\"]');

                                        let techniqueUrl;
                                        let techniqueMethod;
                                        techniqueUrl = `${process.env.REACT_APP_API_URL}coach/programs/techniques`;
                                        techniqueMethod = 'POST';
                                        if (!tech.isDeleted) {
                                            fetch(techniqueUrl, {
                                                method: techniqueMethod, // *GET, POST, PUT, DELETE, etc.
                                                mode: 'cors', // no-cors, cors, *same-origin
                                                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                                                // credentials: 'same-origin', // include, *same-origin, omit
                                                headers: {
                                                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                                                },
                                                // redirect: 'follow', // manual, *follow, error
                                                referrer: 'no-referrer', // no-referrer, *client
                                                body: techniqueFormData
                                            })
                                        }
                                    }
                                })
                                .then(() => {
                                    localStorage.removeItem('draftProgram');
                                    localStorage.setItem('creationMode', 'create');
                                    resetState();
                                });
                        }

                    }


                }

                //          END create seaasion
            });

    }

    const isDisable = () => {
        switch (step) {
            case 1:
                return !Boolean(
                    (title.length > 0 && title.length <= 50)
                    &&
                    (description.length > 0 && description.length <= 500)
                    &&
                    mediaId
                    &&
                    tags.length >= 3
                    &&
                    categoriesList.length >= 1
                );
            case 2:
                let flag = false;
                for (const session of sessions) {
                    if (
                        session.mediaId.length === 0
                        ||
                        session.title.length === 0
                        ||
                        session.description.length === 0
                        ||
                        session.techniques.length == 0
                    ) {
                        flag = true
                    }
                }
                return flag;
            default:
                return false;
        }
    }

    const handleSteps = () => {
        switch (step) {

            case 1:
                setLeftOfTabPanelIndexer('35%');
                setStep(step + 1);
                break;

            case 2:
                setLeftOfTabPanelIndexer('70%');
                setStep(step + 1);
                break;

            case 3:
                createProgram()
                break;

            default:

        }
    }

    const doDeleteSession = () => {
        if (sessions[selectedSession]._id) {     // when this sesion exists on the server
            let sessionUrl = `${process.env.REACT_APP_API_URL}coach/programs/sessions/${sessions[selectedSession]._id}`;
            fetch(sessionUrl, {
                method: 'DELETE', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, cors, *same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                // credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'X-ACCESS-TOKEN': window.localStorage.getItem('token'),
                },
                referrer: 'no-referrer', // no-referrer, *client
            }).then(result => {
                if (result.statusText === 'OK') {
                    let tempSessions = sessions;
                    tempSessions.splice(selectedSession, 1);
                    setSession([...tempSessions]);
                    closeDeleteDialog();
                }
            }).catch(err => {
                console.info('error:', err);
            })
        } else {                            // when this session exists just on the local
            let tempSessions = sessions;
            tempSessions.splice(selectedSession, 1);
            setSession([...tempSessions]);
            closeDeleteDialog();
        }
    }

    return (
        <div
            style={{
                margin: 'auto',
                width: '90%',
                paddingBottom: '1px'
            }}
        >

            <div>

                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <div
                        style={{
                            fontFamily: 'Mulish',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '24px',
                            lineHeight: '28px',
                            color: '#121212',
                        }}
                    >
                        Add new program
                    </div>

                    <div
                        style={{
                            fontFamily: 'Mulish',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            lineHeight: '28px',
                            color: '#121212',
                        }}
                    >
                        Step {step}/3
                    </div>
                </Hidden>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'relative',
                        height: '30px'
                    }}
                >

                    {[1, 2, 3].map(
                        (item, index) =>
                            <div
                                key={index}
                                style={{
                                    borderBottom: '3px solid #ccc9c9',
                                    width: '30%',
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                            >
                                <Hidden only={['xs']}>
                                    {item}
                                </Hidden>
                            </div>
                    )}

                    <div
                        style={{
                            border: '1px solid',
                            backgroundColor: 'blue',
                            width: '30%',
                            height: '1px',
                            position: 'absolute',
                            top: 'calc(100% - 2px)',
                            left: leftOfTabPanelIndexer
                        }}
                    ></div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '10px'
                    }}
                >
                    {[{ label: 'Basic info' }, { label: 'Content' }, { label: 'Publish' }].map(
                        (item, index) =>
                            <div
                                key={index}
                                style={{
                                    width: '30%',
                                    textAlign: 'center',
                                    fontFamily: 'Quicksand',
                                    color: 'black',
                                    fontWeight: 'bold'
                                }}
                            >{item.label}</div>
                    )}

                </div>
            </div>
            {/* manage content */}
            {/* step 1 */}

            <div
                style={{
                    display: step === 1 ? 'block' : 'none',
                }}
            >
                <div
                    style={{
                        margin: '10% 0',
                    }}
                >

                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Name your program</div>
                        <div
                            style={{
                                display: 'flex',
                            }}
                        >
                            <div
                                style={{
                                    width: '70%'
                                }}
                            >
                                <TextFieldWithCounter
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                />
                            </div>


                            <div
                                style={{
                                    width: '30%'
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                    }}
                                >
                                    Choose an attractive and meaningful name
                                </div>
                            </div>
                        </div>
                    </Hidden>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Name your program</div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '100%',
                                        float: 'right',
                                    }}
                                >
                                    Choose an attractive and meaningful name1
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <TextFieldWithCounter
                                    value={title}
                                    onChange={(event) => setTitle(event.target.value)}
                                />
                            </div>
                        </div>
                    </Hidden>
                </div>

                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '35px',
                                color: '#121212',
                                width: '65%'
                            }}
                        >What sport you going to teach by your program?</div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '1rem'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '70%',
                                    justifyContent: 'start',
                                }}
                            >
                                <SportsList
                                    onChecked={onSportChecked}
                                />

                            </div>

                            <div
                                style={{
                                    width: '30%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                        display: 'none'
                                    }}
                                >
                                    For producing a program as a coach you should prove your certifications. (click here)
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '35px',
                                color: '#121212',
                                width: '65%'
                            }}
                        >What sport you going to teach by your program?</div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '1rem',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Muli',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#434343',
                                    width: '80%',
                                    float: 'right',
                                    display: 'none'
                                }}
                            >
                                For producing a program as a coach you should prove your certifications. (click here)
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'start',
                                }}
                            >
                                <SportsList
                                    onChecked={onSportChecked}
                                />

                            </div>

                        </div>
                    </Hidden>
                </div>

                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Specify your programs level</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '70%',
                                    justifyContent: 'start',
                                }}
                            >
                                <LevelsList
                                    onChecked={onLevelChecked}
                                    defaultValue={levelId}
                                />
                            </div>

                            <div
                                style={{
                                    width: '30%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                        display: 'none'
                                    }}
                                >
                                    sdsdsss
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Specify your programs level</div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Muli',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#434343',
                                    width: '80%',
                                    float: 'right',
                                    display: 'none'
                                }}
                            >
                                sdsdsss
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'start',
                                }}
                            >
                                <LevelsList
                                    onChecked={onLevelChecked}
                                    defaultValue={levelId}
                                />
                            </div>

                        </div>
                    </Hidden>
                </div>

                <div
                    style={{
                        margin: '10% 0'
                    }}
                >

                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Uplaod your program advertisment cover</div>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '70%'
                                }}
                            >
                                <UploadBox
                                    label={"Drag your program cover here (image)"}
                                    onUpload={(uploadResult) => uploadProgramCover(uploadResult)}
                                    onRemove={() => removeProgramCover()}
                                    source={programCoverDownloadPath}
                                    type="PROGRAM-PHOTO"
                                    accept=".jpg,.bmp,.png,.gif"
                                />
                            </div>

                            <div
                                style={{
                                    width: '30%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                    }}
                                >
                                    Maximum file size for cover 30MB
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Uplaod your program advertisment cover</div>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Muli',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#434343',
                                    width: '80%',
                                    float: 'right',
                                }}
                            >
                                Maximum time length for video: 30 seconds (less than 50MB)
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >

                                <UploadBox
                                    label={"Drag your program cover here (image)"}
                                    onUpload={(uploadResult) => uploadProgramCover(uploadResult)}
                                    onRemove={() => removeProgramCover()}
                                    source={programCoverDownloadPath}
                                    accept="image"
                                />
                            </div>
                        </div>
                    </Hidden>

                </div>

                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <div
                        style={{
                            fontFamily: 'Mulish',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: '24px',
                            lineHeight: '60px',
                            color: '#121212'
                        }}
                    >Describe your program</div>

                    <Hidden only={['xs']}>
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >

                            <div
                                style={{
                                    width: '70%'
                                }}
                            >
                                <DescriptionTextfiled
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                            </div>

                            <div
                                style={{
                                    width: '30%',
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                    }}
                                >
                                    let your students know about the advantages of your program and your program items.
                                </div>
                            </div>
                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        // width: '70%',
                                        float: 'right',
                                    }}
                                >
                                    let your students know about the advantages of your program and your program items.
                                </div>
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >

                                <DescriptionTextfiled
                                    value={description}
                                    onChange={(event) => setDescription(event.target.value)}
                                />
                            </div>


                        </div>
                    </Hidden>
                </div>
                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Add category for your program</div>

                        {/* <Hidden only={['xs']}> */}
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '70%'
                                }}
                            >
                                {/* <CategoriesList
                                    onCategoriesChange={(categoriesList) => setCategoriesList(categoriesList)}
                                    value={categoriesList}
                                    type='category'
                                    label='Categories'
                                /> */}
                                <Categories
                                    value={categoriesList}
                                    onChange={(value) => setCategoriesList(value)}
                                />
                            </div>

                            <div
                                style={{
                                    width: '30%'
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                    }}
                                >
                                    Choose related categoriues from the list, User can see your program in the choosen categories.
                                </div>
                            </div>

                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Add category for your program</div>

                        {/* <Hidden only={['xs']}> */}
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Muli',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#434343',
                                    width: '80%',
                                    float: 'right',
                                }}
                            >
                                Choose related categoriues from the list, User can see your program in the choosen categories.
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <CategoriesList
                                    onCategoriesChange={(categoriesList) => setCategoriesList(categoriesList)}
                                    value={categoriesList}
                                />

                            </div>

                        </div>
                    </Hidden>
                </div>
                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <Hidden only={['xs']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Add tags for your program</div>

                        {/* <Hidden only={['xs']}> */}
                        <div
                            style={{
                                display: 'flex'
                            }}
                        >
                            <div
                                style={{
                                    width: '70%'
                                }}
                            >
                                <Tags
                                    value={tags}
                                    onChange={(value) => setTags(value)}
                                />
                            </div>

                            <div
                                style={{
                                    width: '30%'
                                }}
                            >
                                <div
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                        width: '80%',
                                        float: 'right',
                                    }}
                                >
                                    Make your program more accessible with tagging
                                </div>
                            </div>

                        </div>
                    </Hidden>
                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '24px',
                                lineHeight: '60px',
                                color: '#121212'
                            }}
                        >Add tags for your program</div>

                        {/* <Hidden only={['xs']}> */}
                        <div
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Muli',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '18px',
                                    color: '#434343',
                                    width: '80%',
                                    float: 'right',
                                }}
                            >
                                Make your program more accessible with tagging1
                            </div>
                            <div
                                style={{
                                    width: '100%'
                                }}
                            >
                                <Tags
                                    value={tags}
                                    onChange={(value) => setTags(value)}
                                />miliiiiiiiiiiii
                            </div>

                        </div>
                    </Hidden>
                </div>


            </div>

            {/* step 2 */}
            <div
                style={{
                    display: step === 2 ? 'block' : 'none',
                }}
            >
                <div
                    style={{
                        margin: '10% 0'
                    }}
                >
                    <div>
                        <div>
                            <Hidden only={['xs']}>

                                <Typography
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '34px',
                                        lineHeight: '38px',
                                        color: '#121212',
                                    }}
                                >
                                    Define your program sessions and upload their content.
                                </Typography>
                            </Hidden>
                            <br />

                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <Typography
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        fontSize: '18px',
                                        lineHeight: '38px',
                                        color: '#121212',
                                    }}
                                >
                                    Define your program sessions and upload their content.
                                </Typography>
                            </Hidden>

                            <Hidden only={['sm', 'lg', 'md', 'xl']}>
                                <Typography
                                    style={{
                                        fontFamily: 'Muli',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        lineHeight: '18px',
                                        color: '#434343',
                                    }}
                                >
                                    Each training session supposed to be conduct in one day. (e.g. 14 sessions means the program take two weeks to finished)
                                </Typography>
                            </Hidden>

                        </div>
                        <Hidden only={['sm', 'lg', 'md', 'xl']}>
                            <hr />
                        </Hidden>

                        <div
                            style={{
                                direction: 'rtl',
                                backgroundColor: '#f3f3f3'
                            }}
                        >

                            <Button
                                variant="text"
                                startIcon={<KeyboardDoubleArrowUpIcon />}
                                style={{
                                    color: 'black',
                                    // width: "30px",
                                    height: "30px"
                                }}
                                title="collaps all"
                                onClick={() => collapseSessions()}
                            >collaps all</Button>

                            <Button
                                variant="text"
                                startIcon={<KeyboardDoubleArrowDownIcon />}
                                style={{
                                    color: 'black',
                                    // width: "30px",
                                    height: "30px"
                                }}
                                title="expand all"
                                onClick={() => expandSessions()}
                            >expand all</Button>

                        </div>

                        <br />

                        <SortableContainer
                            onSortEnd={onSortEnd}
                            useDragHandle
                        >
                            {sessions.map(
                                (item, index) =>
                                (
                                    <Session
                                        item={item}
                                        index={index}
                                        sessions={sessions}
                                        collapseSession={collapseSession}
                                        collapseSessions={collapseSessions}
                                        openDeleteSessionDialog={openDeleteSessionDialog}
                                        setSessionTitle={setSessionTitle}
                                        setSessionDescription={setSessionDescription}
                                        uploadSessionVideo={uploadSessionVideo}
                                        uploadSessionCover={uploadSessionCover}
                                        removeSessionCover={removeSessionCover}
                                        removeSessionVideo={removeSessionVideo}
                                        reArrangeDurations={reArrangeDurations}
                                        addTechniquBar={addTechniquBar}
                                        setVideoProperties={setVideoProperties}
                                        addTechniqueName={addTechniqueName}
                                        addTechniqueRepetitionCount={addTechniqueRepetitionCount}
                                        toggleHasMusic={toggleHasMusic}
                                        removeTechnique={removeTechnique}
                                        updateDuration={updateDuration}
                                        uploadSessoinSoundtrack={uploadSessoinSoundtrack}
                                        removeSessionSoundTrack={removeSessionSoundTrack}
                                        setSessionCalories={setSessionCalories}
                                    />
                                )
                            )}
                        </SortableContainer>

                        <br />
                        <AddSession
                            onClick={() => addNewSession()}
                        />
                    </div>

                </div>
            </div>


            {/* step 3 */}

            <div
                style={{
                    display: step === 3 ? 'block' : 'none',
                }}
            >
                <div
                    style={{
                        margin: '10% 0px 30px 0px'
                    }}
                >

                    <div>
                        <Typography
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontSize: '18px',
                                lineHeight: '28px',
                                color: '#121212'
                            }}
                        >
                            <span
                                style={{
                                    fontWeight: 'bold',
                                }}
                            >Note: </span>
                            {/* Basically, you’ll earn based on your programs visit. more visit means more incom. */}
                            At the moment all created programs are free. It will be possible to create paid programs shortly. We will update you in advance via SMS.
                        </Typography>
                    </div>

                    <div
                        style={{
                            marginTop: '50px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: '28px',
                                letterSpacing: '0px',
                                textAlign: 'left',
                            }}
                        >
                            Is it a free program?
                        </div>

                        <div>
                            <FormControlLabel
                                value="start"
                                control={<Switch
                                    color="primary"
                                    checked={true}
                                />}
                                label="yes"
                                labelPlacement="start"
                            />
                        </div>

                    </div>
                    {/* <hr />

                    <div
                        style={{
                            fontFamily: 'Quicksand',
                            fontSize: '24px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '30px',
                            letterSpacing: '0px',
                            textAlign: 'left',
                        }}
                    >
                        Unique referral code for your desired prommotion
                    </div> */}
                    {/* <div
                        style={{
                            fontFamily: 'Quicksand',
                            fontSize: '34px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: '43px',
                            letterSpacing: '0px',
                            textAlign: 'left'
                        }}
                    >
                        SCP001
                    </div> */}
                </div>
            </div>


            {/* Action Bar */}
            {/* <hr /> */}
            <Hidden only={['xs']}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        margin: '45px 0'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '270px'
                        }}
                    >
                        {step !== 1 && <NextButton
                            onClick={() => backToStep()}
                        >
                            Back
                        </NextButton>
                        }
                        <DiscardButton
                            discardProgram={() => discardProgram()}
                        >
                            Discard
                        </DiscardButton>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            width: '270px'
                        }}
                    >

                        <div>
                            <NextButton
                                onClick={() => handleSteps()}
                                disabled={isDisable()}
                            >
                                {step !== 3 ? 'Next' : 'Publish'}
                            </NextButton>
                        </div>

                    </div>

                </div>
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                <div
                    style={{
                        display: 'flex',
                        columnGap: 19,
                        justifyContent: 'space-between',
                        margin: '45px 0',
                    }}
                >
                    <MobileDiscardButton>Discard</MobileDiscardButton>
                    <MobileNextButton
                        onClick={() => backToStep()}
                        disabled={step === 1}
                    >
                        NEXT
                    </MobileNextButton>
                </div>
            </Hidden>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                TransitionComponent={SlideTransition}
                open={showSnackbar}
                onClose={() => setShowSnackbar(false)}
                message="not enought space on timeline"
                autoHideDuration={6000}
                key={1}
                action={
                    <React.Fragment>
                        <Button
                            color="primary"
                            size="small"
                            onClick={() => setShowSnackbar(false)}
                        >
                            OK
                        </Button>
                    </React.Fragment>
                }
            />

            <Dialog
                open={deleteSessionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Session?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        I'm sure, please delete session
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => doDeleteSession()}
                        color="error"
                        variant="outlined"
                    >Delete Session</Button>
                    <Button
                        // onClick={() => closeDeleteDialog()}
                        onClick={() => setDeleteSessionDialog(false)}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}

export default TabPanel;
