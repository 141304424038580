import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';

const MyButton = styled(Button)({
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '70%',
    minWidth: 100,
    maxWidth: 240,
    height: '64px',
    textTransform: 'none',
    backgroundColor: '#182A7D',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'white',
    '&:hover': {
        backgroundColor: '#E0F1FF',
        color:'#182A7D'
    }
});

const MobileNextButton = (props) => {
    
    const doNext = () => {
        props.onClick()
    }

    return (
        <MyButton
            variant="outlined"
            color="primary"
            onClick={() => { doNext() }}
        >
            {props.children}
        </MyButton>
    )
}

export default MobileNextButton;