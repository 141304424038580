import React, { useState } from 'react';
import {
    fade,
    withStyles,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { Typography } from '@material-ui/core';

const BootstrapInput = withStyles((theme) => ({
    root: {
        width: '100%',
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: '#EFEFF0',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        height: 108,
        padding: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}))(InputBase);

export default function DescriptionTextfiled(props) {
    const [isDirty, setIsDirty] = useState(false);
    const handleChange = (e) => {
        setIsDirty(true);
        props.onChange(e);
    }
    return (
        <React.Fragment>
            <BootstrapInput
                value={props.value}
                onChange={(e) => handleChange(e)}
                multiline
                rows={4}
            />
            {isDirty && !props.value.length && <Typography
                style={{
                    color: '#f44336',
                    marginTop: 2,
                    marginLeft: 4,
                }}
            >fill the form</Typography>}
        </React.Fragment>
    );
}