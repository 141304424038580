import React from 'react';
import SuccessMessage from '../SuccessMessage';

class SuccessfullyCreated extends React.Component {

    render() {

        return (
            <React.Fragment>
                <SuccessMessage />
            </React.Fragment>
        );
    }
}

export default SuccessfullyCreated;
