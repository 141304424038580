import React from 'react';
import TimeDuration from './TimeDuration';
import FadeMenu from '../Menu';
import Counter from '../Counter';
import TextField from '@material-ui/core/TextField';
import Switch from '@mui/material/Switch';

class TechniqueRow extends React.PureComponent {

    componentDidMount = () => {
        this.setState({
            anchorEl: null,
            open: false
        });
    }

    updateRepetitionCount = (repetitionCount) => {
        this.props.addTechniqueRepetitionCount(repetitionCount);
    }

    state = {
        open: false,
        ITEM_HEIGHT: 48,
        options: [
            'None',
            'Atria',
            'Callisto',
            'Dione',
            'Ganymede',
        ],
        anchorEl: null
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            open: true
        })
    };

    toggleHasMusic = (event) => {
        this.props.toggleHasMusic(event.target.checked);
    }

    handleClose = () => {
        this.setState({
            anchorEl: null,
        })
    };

    render() {
        const classes = {
            appBar: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0'
            },
            input: {
                width: '34px',
                marginRight: '5px',
                marginLeft: '5px',
                borderStyle: 'none',
                fontSize: '30px',
                '&:focus': {
                    outline: 'none'
                }
            },
            numberOfRepetitions: {
                border: '1px solid #121212',
                borderRadius: '10px',
                width: '162px',
                height: '54px',
                display: 'flex',
                justifyContent: 'center',

            },
            numberOfRepetitionsItem: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '24px',
                lineHeight: '30px',
                color: '#121212'
            },
            minusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderLeftStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            plusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }
        }
        return (
            <div
                style={classes.appBar}
            >
                <div
                    id="maincon"
                    style={{
                        display: 'flex',
                        alignItems: "flex-start"
                    }}
                >
                    <div>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontWeight: 'normal',
                                fontSize: 14,
                                color: '#121212',
                                paddingBottom: '3%'
                            }}
                        >
                            Activity name
                        </div>

                        <div
                            style={{
                                height: '54px',
                                width: '292px',
                                background: '#FFFFFF',
                                border: '1px solid #121212',
                                boxSizing: 'border-box',
                                borderRadius: '10px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#878787',
                                paddingLeft: 15
                            }}
                        >
                            <TextField
                                id="standard-basic"
                                value={this.props.item.title}
                                onChange={(e) => this.props.addTechniqueName(e.target.value)}
                            />
                        </div>
                    </div>

                    <div style={{ padding: '0 20px' }}>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontWeight: 'normal',
                                fontSize: 14,
                                color: '#121212',
                                paddingBottom: '7%'
                            }}
                        >
                            Duration
                        </div>
                        <TimeDuration
                            duration={this.props.item.duration}
                            reArrangeDuration={(newDuration) => this.props.reArrangeDuration(newDuration)}
                        />
                    </div>

                    {!['explanation', 'explaination', 'rest'].includes(this.props.item.typeLabel.toLowerCase()) && <div>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontWeight: 'normal',
                                fontSize: 14,
                                color: '#121212',
                                paddingBottom: '6%'
                            }}
                        >
                            Number of repetitions
                        </div>
                        <Counter
                            value={this.props.item.repetitionCount}
                            updateRepetitionCount={(val) => this.updateRepetitionCount(val)}
                        />

                    </div>
                    }
                    {!['explanation', 'explaination', 'rest'].includes(this.props.item.typeLabel.toLowerCase())
                        &&
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                justifyContent: 'space-between',
                                padding: '0px 20px'
                            }}
                        >
                            <div
                                style={{
                                    fontFamily: 'Mulish',
                                    fontWeight: 'normal',
                                    fontSize: 14,
                                    color: '#121212',
                                    paddingBottom: '6%'
                                }}
                            >
                                Play Music
                            </div>
                            <div>
                                <Switch
                                    label="Required"
                                    defaultChecked
                                    onChange={(e) => this.toggleHasMusic(e)}
                                    checked={this.props.item.isMusicOn}
                                />
                            </div>
                        </div>
                    }

                </div>
                <div>
                    <div
                        style={{
                            fontFamily: 'Mulish',
                            fontWeight: 'normal',
                            fontSize: 14,
                            color: '#121212',
                            paddingBottom: '6%',
                            height: 30
                        }}
                    >

                    </div>

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            height: '50%'
                        }}
                    >
                        <FadeMenu
                            removeTechnique={() => this.props.removeTechnique()}
                        />
                    </div>
                </div>

            </div >
        )
    }

}

export default TechniqueRow;