import { GET } from "./DataProvider";

/**
 * All general functions are in this class;
 */
class GeneralFunctions {

    /**
     * returns token status
     */
    static async IsTokenActive() {
        const resultTags = await GET("tags", null, { 'X-ACCESS-TOKEN': this.getCurrentToken() });
        if (resultTags && resultTags.length > 0) return true;
        return false;
    }

    /**
     * returns current saved token for user
     */
    static getCurrentToken() {
        return localStorage.getItem("token");
    }

    /**
     * return human friendly verison of seconds
     */

    static convertSecondsToHumanReadable(value) {
        let totalSeconds = parseInt(value);
        let hours = Math.floor(totalSeconds / 3600);
        totalSeconds %= 3600;
        let minutes = Math.floor(totalSeconds / 60);
        let seconds = Math.floor(totalSeconds % 60);

        // If you want strings with leading zeroes:
        minutes = String(minutes).padStart(2, "0");
        hours = String(hours).padStart(2, "0");
        seconds = String(seconds).padStart(2, "0");

        let finalString;


        if (hours === '00' && minutes === '00') {
            finalString = minutes + ":" + seconds;
        }
        else if (hours === '00' && minutes !== '00') {
            finalString = minutes + ":" + seconds;
        }
        else {
            finalString = hours + ":" + minutes + ":" + seconds
        }
        return finalString;
    }

}

export { GeneralFunctions };