import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    timeLine: {
        position: 'relative',
        // width: 706,
        width: '100%',
        height: 24,
        // borderRadius: 6,
        backgroundColor: '#bdbdbd',
    },
    contextMenuItem: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontSize: 16,
        color: '#FFFFFF',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 900,
        }
    }
});
export const Timeline = (props) => {
    const classes = useStyles();

    return (
        <div
            tabindex="-1"
            className={classes.timeLine}
            id="timeline"
        >

            {/* <div
                style={{
                    position: 'absolute',
                    width: 2,
                    backgroundColor: '#ffee58',
                    height: '100%',
                    bottom: 0,
                    left: 0
                }}
            /> */}

        </div >
    )
}