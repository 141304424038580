import React from 'react';
import TabPanel from '../layouts/TabPanel';

class NewProgram extends React.Component {
    render() {
        return (
            <TabPanel />
        );
    }
}

export default NewProgram;
