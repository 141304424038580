import React from 'react';
import CheckBox from './CheckBox';
import { GET } from '../helpers/DataProvider';

class LevelsList extends React.Component {

    state = {
        levelsList: [],
        bakedLevelsList: []
    }

    getData = async () => {
        const resultPrograms = await GET("params/levels", null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        let tempObj = {};
        let bakedLevelsList = [];
        Object.entries(resultPrograms[0]).map(
            (item, index) => {
                tempObj = {};
                tempObj.title = item[1];
                tempObj.id = index;
                tempObj.checked = tempObj.id === this.props.defaultValue.id;
                bakedLevelsList.push(tempObj);
            });
        this.setState({
            levelsList: bakedLevelsList
        }
            ,
            () => {

                this.onChecked(this.props.defaultValue || bakedLevelsList[0])
            }
        );
    }

    componentDidMount() {
        this.getData();
    }

    componentWillReceiveProps(nextProps) {
    }

    onChecked = (targetValue) => {
        this.state.levelsList.map(
            (item, index) => {
                item.id === targetValue.id ? item.checked = true : item.checked = false
            }
        );
        this.props.onChecked(targetValue, 'levelList');
    }

    render() {
        return (
            <>
                {this.state.levelsList.map(
                    (item, index) =>
                        <React.Fragment
                            key={index}
                        >
                            <CheckBox
                                key={index}
                                item={item}
                                onChecked={this.onChecked}
                            />
                        </React.Fragment>
                )}
            </>
        );
    }
}

export default LevelsList;
