import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { setUserinfo, setUserLogout } from "../../redux/actions";
import store from '../../redux/store';

const MyButton = styled(Button)({
    height: '48px',
    width: '118px',
    color: 'white',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '12px',
    textTransform: 'none',
    fontFamily: 'Mulish',
    fontWeight: 700,
    fontSize: 16
});

interface ILogoutButton {
}

const LogoutButton: React.FC<ILogoutButton> = () => {

    const logout = () => {
        // get OTP
        let formData = new FormData();
        let mobileValue: string | null | any = window.localStorage.getItem('mobile');
        formData.append('mobile', mobileValue);
        fetch(`${process.env.REACT_APP_API_URL}auth/logout`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                // 'X-ACCESS-ROLE': 1
            },
            referrer: 'no-referrer', // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
        })
            .then(logoutResult => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('mobile');
                window.localStorage.removeItem('last-location');
                store.dispatch(setUserLogout());
                let userData = {
                    isLogin: false,
                    token: null
                }
                store.dispatch(setUserinfo(userData));
                window.location.href = '#/login';
                return logoutResult;
            })
    }


    return (
        <MyButton
            onClick={() => logout()}
        >
            Logout
        </MyButton>
    )
}

export default LogoutButton