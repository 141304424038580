import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';

const MyButton = styled(Button)({
    border: '2px solid #182A7D',
    boxSizing: 'border-box',
    borderRadius: '10px',
    width: '70%',
    minWidth: 100,
    maxWidth: 240,
    height: '64px',
    textTransform: 'none',
    color: '#182A7D',
    fontFamily: 'Mulish',
    fontWeight: 'bold',
    fontSize: '18px'
});

const MobileDiscardButton = (props) => {
    return (
        <MyButton variant="outlined" color="primary">
            {props.children}
        </MyButton>
    )
}

export default MobileDiscardButton;