import React from 'react';
import { withStyles } from "@material-ui/core/styles";

class Segment extends React.Component {
    startPosition = 0;     // start position mousedown event
    currentPosition = 0;     // count current translateX value
    distancePosition = 0;     // count distance between "down" & "move" event
    isMouseDown = false; // check if mouse is down or not
    needForRAF = true;

    constructor(props) {
        super(props);
        this.segmentRef = React.createRef();
        this.handlerRef = React.createRef();
    }

    state = {
        isPopup: false
    }

    segmentRef;
    m_pos;
    BORDER_SIZE = 4;
    direction;
    rightPostion;
    leftPostion;

    resizeLeft = (e) => {
        const dx = this.m_pos - e.x;
        this.m_pos = e.x;
        this.segmentRef.current.style.right = e.currentTarget.rightPostion + "px";
        this.segmentRef.current.style.width = (parseInt(getComputedStyle(this.segmentRef.current, '').width) + dx) + "px";
    }

    resizeRight = (e) => {
        const dx = this.m_pos - e.x;
        this.m_pos = e.x;
        let bakedWidth = parseInt(getComputedStyle(this.segmentRef.current, '').width) - dx;
        this.props.reArrangeDurations(bakedWidth);
    }

    getTranslateX = () => {
        var translateX = parseInt(getComputedStyle(this.handlerRef.current, null).getPropertyValue("transform").split(",")[4]);
        return translateX; // get translateX value
    }

    onMouseDown = (e) => {
        e.preventDefault(); // reset default behavior
        e.stopPropagation();
        this.isMouseDown = true;
        this.currentPosition = this.getTranslateX(); // get current translateX value
        this.startPosition = e.clientX;
        document.addEventListener("mousemove", this.mouseMove);
        document.addEventListener("mouseup", this.mouseUp);
        this.props.onMouseDownHappened();
    }

    calculateSegmentsWidth = () => {
        let sum = 0;
        for (let segment of this.props.allSegments) {
            sum += segment.width
        }
        return sum
    }

    checkDirection = () => {
        if (this.nextPosition < this.currentPosition) {
            return 'left';
        } else {
            return 'right';
        }
    }

    canGrow = () => {
        if (this.checkDirection() === 'left') {
            return true;
        } else if (this.checkDirection() === 'right') {
            let segmentsWidth = this.calculateSegmentsWidth();
            if (segmentsWidth + (this.props.allSegments.length * 3) >= this.props.containerwidth) {
                return false;
            }
            return true;
        }
    }

    mouseMove = (e) => {
        this.nextPosition = (e.clientX - this.startPosition) + this.currentPosition;
        if (this.canGrow()) {
            e.preventDefault();
            e.stopPropagation();
            this.distancePosition = (e.clientX - this.startPosition) + this.currentPosition; // count it!  
            if (this.needForRAF && this.isMouseDown) {
                this.needForRAF = false;            // no need to call rAF up until next frame
                requestAnimationFrame(this.update); // request 60fps animation
            }
            this.props.reArrangeDurations(this.distancePosition);
        } else {
            return false;
        }
    }

    mouseUp = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.isMouseDown = false;
        document.removeEventListener("mousemove", this.mouseMove, false);
        document.removeEventListener("mousedown", this.onMouseDown, false);
        this.props.onMouseUpHappened();
    }

    update = () => {
        this.needForRAF = true;
        this.handlerRef.current.style.transform = "translateX(" + this.distancePosition + "px)";
    }

    mu = (e) => {
        this.segmentRef.current.removeEventListener("mousemove", this.resizeLeft, false);
    }

    componentDidMount() {
        this.segmentRef.current.addEventListener("mousemove", this.showPopup);
        this.segmentRef.current.addEventListener("mouseout", this.hidePopup);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.weAreInResizeMode) {
            this.segmentRef.current.removeEventListener("mousemove", this.showPopup, false);
            this.segmentRef.current.removeEventListener("mouseout", this.hidePopup, false);
        } else {
            this.segmentRef.current.addEventListener("mousemove", this.showPopup);
            this.segmentRef.current.addEventListener("mouseout", this.hidePopup);
        }
    }

    calculateWidth = (durationInSecond) => {
        let segmentWidth = parseInt((durationInSecond * this.props.width) / this.props.mediaDuration);
        return (segmentWidth + 'px');
    }

    calculateLeft = (startSecond) => {
        let segmentLeft = parseInt((startSecond * this.props.width) / parseInt(this.props.mediaDuration));
        return (segmentLeft + 'px');
    }

    calculateBackgroundcolor = (segmentType) => {
        switch (segmentType.toLowerCase()) {
            case 'rest':
            case 'Rest':
                return '#EFEFF0';
            case 'workout':
                return '#FCC0CE';
            case 'explaination':
            case 'explanation':
                return '#EBFF00';
            default:
        }
    }

    calculateHandlerLeft = (segmentData) => {
        return segmentData.left + segmentData.width
    }

    showPopup = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isPopup: true,
            clientX: getComputedStyle(e.target, null).getPropertyValue('left'),
            clientY: 24
        });
    }

    hidePopup = (e) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            isPopup: false
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.state.isPopup &&
                    <div
                        style={{
                            width: 150,
                            height: 120,
                            backgroundColor: 'rgb(238 233 225 / 95%)',
                            position: 'absolute',
                            top: 24,
                            left: this.state.clientX,
                            zIndex: 1,
                            borderRadius: 3,
                            opacity: 0.9,
                            border: '1px solid gray'
                        }}
                    >
                        <div
                            style={{
                                fontWeight: 'bold'
                            }}
                        >{this.props.data.title}1</div>
                        <div>start second : {Number(this.props.data.startSecond).toFixed(2)}</div>
                        <div>duration : {Number(this.props.data.duration).toFixed(2)}</div>
                        <div>end second : {Number(this.props.data.endSecond).toFixed(2)}</div>
                        <div>type : <span style={{ fontWeight: 'bold' }}>{this.props.data.typeLabel}</span></div>
                    </div>
                }

                <div
                    ref={this.segmentRef}
                    className={classes.rightPanel}
                    style={{
                        position: 'absolute',
                        width: this.props.data.width,
                        backgroundColor: this.calculateBackgroundcolor(this.props.data.typeLabel),
                        left: this.props.data.left,
                        textAlign: 'center',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                    onClick={(e) => e.stopPropagation(e)}
                >
                    {this.props.data.title}
                </div>

                <div
                    className={classes.handler}
                    ref={this.handlerRef}
                    style={{
                        transform: `translateX(${this.calculateHandlerLeft(this.props.data)}px)`,
                    }}
                    onMouseDown={(e) => this.onMouseDown(e)}
                    onClick={(e) => e.stopPropagation(e)}
                />
            </React.Fragment >
        );
    }
}

const myStyle = (theme) => ({
    rightPanel: {
        backgroundColor: 'green',
        height: 'inherit',
        cursor: 'pointer'
    },
    handler: {
        cursor: 'w-resize',
        backgroundColor: 'red',
        width: 3,
        height: '100%',
        position: 'absolute',
    }
});

export default withStyles(myStyle)(Segment);
