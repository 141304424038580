import { GeneralFunctions } from "./GeneralFunctions";

/**
 * The DataProvider class has general methods for GET, POST and other helper method for requesting from web services.
 */
const getHeadersApiRequest = () => {
    const token = GeneralFunctions.getCurrentToken();
    return {
        authorization: `Bearer ${token}`,
        'X-ACCESS-ROLE': 1,
    };
};

const prepareParams = async (data, formDataType) => {
    let formData = new FormData();
    switch (formDataType) {
        case 'formData':
            Object.keys(data).forEach(function (key) {
                formData.append(key, data[key]);
            });
            return formData;

        case 'raw':
            return data;
    }
};

const request = async (method, url, apiType, params = null, header = null, data = null, formDataType) => {     // apiType : [SocketAPI, ServerApi, AuthenticationApi, ClaimsAPI]

    const headerRequest = header !== null ? header : getHeadersApiRequest();
    let baseURL;
    switch (apiType) {
        case 'SocketAPI':
            baseURL = `${process.env.REACT_HOST_URL}`;
            break;
        case 'ServerApi':
            baseURL = `${process.env.REACT_APP_API_URL}`;
            break;
        case 'AuthenticationApi':
            baseURL = `${process.env.REACT_HOST_URL}`;
            break;
    }

    data = await prepareParams(data, formDataType);
    return fetch(`${process.env.REACT_APP_API_URL + url}`, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: headerRequest,
        referrer: 'no-referrer', // no-referrer, *client
        body: data, // body data type must match "Content-Type" header
    })
        .then(response => {
            return response.json()
        })
        .then(resultPrograms => {
            return resultPrograms
        })
};

export const GET = async (url, params = null, header = null, apiType = 'ServerApi', formDataType = 'formData') => {
    return request('GET', url, apiType, params, header, formDataType).then(function (response) {
        if (response && response.data)
            return response.data;
        return [];
    }).catch(function (error) {
        if (error.response && error.response.status && error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/";
            window.location.reload();
        }
        return error.response;
    });
};

export const POST = async (url, data = null, params = null, header = null, apiType = 'ServerApi', formDataType = 'formData') => {            // apiType : [SocketAPI, ServerApi, AuthenticationApi, ClaimsAPI]
    return request('POST', url, apiType, params, header, data, formDataType)
        .then(function (response) {
            if (response && response.data)
                return response.data;
            return [];
        })
        .catch(function (error) {
            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = "/";
                window.location.reload();
            }
            return error.response;
        });
};

export const PUT = async (url, data = null, params = null, header = null, apiType = 'ServerApi', formDataType = 'formData') => {            // apiType : [SocketAPI, ServerApi, AuthenticationApi, ClaimsAPI]
    return request('PUT', url, apiType, params, header, data, formDataType)
        .then(function (response) {
            if (response && response.data)
                return response.data;
            return [];
        })
        .catch(function (error) {
            if (error.response && error.response.status && error.response.status === 401) {
                sessionStorage.clear();
                window.location.href = "/";
                window.location.reload();
            }
            return error.response;
        });
};

export const Delete = async (url, params = null, header = null, apiType = 'ServerApi') => {
    return request('DELETE', url, apiType, params, header).then(function (response) {
        if (response && response.data)
            return response.data;
        return [];
    }).catch(function (error) {
        if (error.response && error.response.status && error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/";
            window.location.reload();
        }

        return error.response;
    });
};