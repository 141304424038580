import React from 'react';
import UnderConstruct from './UnderConstruct';
class Reports extends React.Component {

    render() {

        return (
            <UnderConstruct />
        );
    }
}

export default Reports;
