import React from 'react';
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import NewProgramButton from '../components/buttons/NewProgramButton';
import SidebarButton from '../components/buttons/SidebarButton';
import { ReactComponent as PackageIcon } from '../assets/icons/package.svg';
import { ReactComponent as PieChartIcon } from '../assets/icons/pie-chart.svg';
import { ReactComponent as CreditCardIcon } from '../assets/icons/credit-card.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as PlussquareIcon } from '../assets/icons/plus-square.svg';
import IntroductionVideo from '../components/IntroductionVideo';

const useStyles = (theme) => ({

    paper1: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '30px'
    },

});

class LeftSidebarItems extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            this.props.isLogin ?
                <div
                    className={classes.paper1}
                >
                    <NewProgramButton
                        icon={<PlussquareIcon />}
                        href={`/new-program`}
                    />
                    <div
                        style={{
                            marginTop: '60px',
                            display: 'flex',
                            flexDirection: 'column'
                        }}>
                        <SidebarButton icon={<PackageIcon />} href={`/programs-list`}>Programs</SidebarButton>
                        <SidebarButton icon={<PieChartIcon />} href={`/reports`}>Reports</SidebarButton>
                        <SidebarButton icon={<CreditCardIcon />} href={`/finance`}>Finance</SidebarButton>
                        <SidebarButton icon={<SettingsIcon />} href={`/settings`}>Settings</SidebarButton>
                        {/* <div>
                            <IntroductionVideo />
                            <IntroductionVideo />
                            <IntroductionVideo />
                        </div> */}
                    </div>
                </div>
                : null
        )
    }
}

const mapStateToProps = state => {
    const isLogin = state.isLogin;

    return {
        isLogin
    }
}

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps)
)(LeftSidebarItems)