import React from 'react';
import { ProgramBox } from '../components/ProgramBox';
import { GET } from '../helpers/DataProvider';
import { connect } from "react-redux";
import ProgramBoxSkeleton from '../ProgramBoxSkeleton';
import NoProgram from './NoProgram';
import { Typography } from '@material-ui/core';

const Dispatcher = ({ data }) => {
    if (data === null) {
        return <ProgramBoxSkeleton />;
    }
    else if (data.length === 0) {
        return <div>
            <NoProgram />
        </div>
    }
    else {
        return (
            <>
                <Typography>you have <span style={{ fontWeight: 'bold' }}>{data.length}</span> programs</Typography>{data.map(
                    (item, index) =>
                        <ProgramBox
                            key={index}
                            data={item}
                        />
                )}
            </>
        )
    }
}
class ProgramsList extends React.Component {

    state = {
        coachPrograms: null,
        totalPrograms: 0
    }

    fetchMyPrograms = async () => {
        const resultPrograms = await GET("coach/programs", null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        this.setState({
            coachPrograms: resultPrograms,
            totalPrograms: resultPrograms.length
        });
    }

    componentDidMount() {
        this.fetchMyPrograms()
    }

    render() {
        return (
            <React.Fragment>
                <Dispatcher data={this.state.coachPrograms} />
                {/*{
                    !this.state.coachPrograms.length ?

                        this.state.coachPrograms.map(
                            (item, index) =>
                                <ProgramBox
                                    key={index}
                                    data={item}
                                />
                        )
                        :
                        <ProgramBoxSkeleton />
                }*/}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {

    const isLogin = state.isLogin;

    return {
        isLogin
    }
}

export default connect(mapStateToProps)(ProgramsList)