import React from 'react';
// import Button from '@material-ui/core/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
//import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { compose } from "redux";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { setUserinfo } from "../redux/actions";
import { ButtonBase, Typography } from '@material-ui/core';
import { Redirect, Navigate } from "react-router-dom";
import InputAdornment from '@material-ui/core/InputAdornment';
import SettingsPhone from '@material-ui/icons/SettingsPhone';
import { AuthPOST, GET } from "../helpers/DataProvider";
import { GeneralFunctions } from '../helpers/GeneralFunctions';
import { MuiPhone } from './Test';

const useStyles = (theme) => ({

    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },

    correctMobile: {
        textAlign: 'end',
        color: '#1e88e5',
        fontFamily: 'Mulish',
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer'
        },
        fontFamily: 'Mulish'
    }

});

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}


class Login extends React.Component {

    constructor(props) {
        super(props)
        this.verificationContainer = React.createRef();
    }

    handlePhoneNumberValidation = (isValid) => {
        this.setState({
            isPhoneNumberVerified: isValid
        });
    };

    ERROR_TEXT = 'you are not admin';
    YOU_ARE_NOT_COACH = 'you are not coach';
    DEFAULT_TEXT = '';

    state = {
        mobile: '',
        pendingGetOTP: false,
        pendingVerify: false,
        error: false,
        step: 0,
        otp: null,
        token: null,
        verificationValue: new Array(4),
        open: false,
        Transition: Fade,
        isPhoneNumberVerified: false,
        isCoach: null
    }

    componentDidMount = () => {

    }

    handleClick = (Transition) => {
        this.setState({
            open: true,
            Transition,
        });
    };

    handleClose = (Transition) => {
        this.setState({
            open: false
        });
    };

    getOTP = () => {
        this.setState({
            pendingGetOTP: true
        })
        // get OTP
        let formData = new FormData();
        formData.append('mobile', this.state.mobile);
        fetch(`${process.env.REACT_APP_API_URL}auth/otp`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'X-ACCESS-ROLE': 1
            },
            referrer: 'no-referrer', // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
        })
            .then(OTPResult => {
                return OTPResult.json()
            })
            .then(result => {
                if (result.error && result.error.code === 404) {
                    this.setState({
                        helperText: this.ERROR_TEXT,
                        error: true,
                        pendingGetOTP: false
                    })
                    return;
                }
                else if (result.error && result.error.code === 401) {
                    console.info('shoma morabi nisti')
                    this.setState({
                        helperText: this.YOU_ARE_NOT_COACH,
                        error: true,
                        pendingGetOTP: false
                    })
                    return;
                }
                else {
                    this.setState({
                        token: result.data[0],
                        step: 1
                    }, () => {
                        this.verificationContainer.current.children[0].children[0].children[0].focus();
                    })
                }
            })
    }

    doLogin = () => {
        this.setState({
            pendingVerify: true
        });
        // do login
        let formDataLogin = new FormData();
        formDataLogin.append('otp', this.state.verificationValue.join(""));
        let myHeaders = new Headers();
        myHeaders.append('X-ACCESS-ROLE', '1');
        fetch(`${process.env.REACT_APP_API_URL}auth/login`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            // credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'X-ACCESS-TOKEN': this.state.token
            },
            // redirect: 'follow', // manual, *follow, error
            referrer: 'no-referrer', // no-referrer, *client
            body: formDataLogin, // body data type must match "Content-Type" header
        }).then(async (resultLogin) => {
            window.localStorage.setItem('token', this.state.token);
            window.localStorage.setItem('mobile', this.state.mobile);
            this.setState({
                // pendingGetOTP: false,
                pendingVerify: false
            })
            if (resultLogin.status === 403) {
                this.handleClick(SlideTransition);
            } else {
                let userFullInformation = await GET('profile', null, { 'X-ACCESS-TOKEN': GeneralFunctions.getCurrentToken() })
                // dispatch data
                let userData = {
                    isLogin: true,
                    token: this.state.mobile,
                    userFullInformation: userFullInformation[0]
                }
                this.props.dispatch(setUserinfo(userData));
                this.props.history.push("/new-program");
            }

        })
            .catch((error) => {
                return Promise.reject()
            })
            ;
    }

    clearForm = () => {
        this.setState({
            mobile: '',
            error: false,
            helperText: this.DEFAULT_TEXT
        })
    }

    correctMobile = () => {
        this.setState({
            step: 0,
            mobile: '',
            pendingGetOTP: false,
            error: false,
            otp: null,
            token: null
        });
    }

    setVerficationValue = (event, index) => {
        event.persist();

        let tempArray = this.state.verificationValue;
        tempArray[index] = event.target.value;
        this.setState({
            verificationValue: tempArray
        });
    }

    handleChange = (pr) => (event) => {
        event.persist();
        this.setState({ [pr]: event.target.value });
    };

    submitGetOTPForm = (event) => {
        if (event.key === 'Enter' || event.charCode === 13) {
            this.getOTP()
        }
    }

    handleKeydown = (event, index) => {
        if (event.nativeEvent.code === 'Backspace') {
            if (this.verificationContainer.current.children[index - 1])
                window.setTimeout(() =>
                    this.verificationContainer.current.children[index - 1].children[0].children[0].focus()
                    , 0);
            // this.verificationContainer.current.children[2].children[0].children[0].focus()
        }
        // else if (this.verificationContainer.current.children[index - 1] && event.nativeEvent.code === 'Backspace') {
        //     this.verificationContainer.current.children[index - 1].children[0].children[0].focus()
        // }
    }

    handleKeyPress = (event, index) => {
        if (!isNaN(parseInt(event.key))) {
            if (index < 3) {
                window.setTimeout(() =>
                    this.verificationContainer.current.children[index + 1].children[0].children[0].focus()
                    , 0);
            }
        } else {
            if (event.key === 'Enter' || event.charCode === 13) {
                this.doLogin();
            } else {
                event.preventDefault();
            }
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                style={{
                    flexGrow: 1,
                }}
            >
                <Grid container spacing={3}
                    style={{
                        justifyContent: 'center',
                        minHeight: '800px'
                    }}
                >
                    <Grid
                        item
                        xs={12} sm={12} md={12} lg={8} xl={8}

                    >
                        <Paper
                            style={{
                                padding: '0 20px'
                            }}
                        >
                            <Grid container spacing={2}>

                                {this.state.step === 0 &&
                                    <Grid item xs={12}>

                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Mulish'
                                            }}
                                        >Enter your mobile number</Typography>
                                        <br />
                                        <MuiPhone
                                            disableDialCodeAndPrefix={true}
                                            showDisabledDialCodeAndPrefix={true}
                                            savePhone={(phoneNumber) => this.setState({ mobile: phoneNumber })}
                                            getOtp={(e) => this.getOTP(e)}
                                        />
                                        {this.state.error &&
                                            <Typography>{this.state.helperText}</Typography>
                                        }

                                    </Grid>
                                }

                                {this.state.step === 1 &&
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontWeight: 'bold',
                                                fontFamily: 'Mulish'
                                            }}
                                        >Enter your 4 digits verification code</Typography>
                                        <br />
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                width: 'fit-content',
                                                margin: 'auto'
                                            }}
                                            ref={this.verificationContainer}
                                        >
                                            {[1, 2, 3, 4].map(
                                                (item, index) =>
                                                    <TextField
                                                        style={{
                                                            fontSize: 26,
                                                            fontFamily: 'Mulish',
                                                            width: 70,
                                                            textAlign: 'center',
                                                            margin: '0px 10px',
                                                        }}
                                                        inputProps={{
                                                            style: {
                                                                textAlign: 'center',
                                                                fontFamily: 'Mulish',
                                                                fontSize: 50
                                                            },
                                                            maxlength: "1"
                                                        }}
                                                        value={this.state.verificationValue[index]}
                                                        onChange={(e) => this.setVerficationValue(e, index)}
                                                        onKeyPress={(e) => this.handleKeyPress(e, index)}
                                                        onKeyDown={(e) => this.handleKeydown(e, index)}
                                                    />
                                            )}
                                        </div>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >

                                        {this.state.step === 0 &&
                                            <LoadingButton
                                                loading={this.state.pendingGetOTP}
                                                loadingPosition="start"
                                                variant="outlined"
                                                style={{
                                                    marginRight: '1rem'
                                                }}
                                                startIcon={<ScheduleSendIcon />}
                                                // color="primary"
                                                onClick={(e) => this.getOTP(e)}
                                                disabled={!this.state.mobile.length}
                                            >
                                                Send OTP
                                            </LoadingButton>
                                        }
                                        {this.state.step === 1 &&
                                            <LoadingButton
                                                loading={this.state.pendingVerify}
                                                loadingPosition="start"
                                                variant="outlined"
                                                style={{
                                                    marginRight: '1rem'
                                                }}
                                                // color="primary"
                                                onClick={() => this.doLogin()}
                                            //startIcon={<VpnKeyIcon />}
                                            // disabled={this.state.pendingGetOTP}
                                            >
                                                Verify Code
                                            </LoadingButton>
                                        }

                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => this.clearForm()}
                                            startIcon={<ClearIcon />}
                                        >
                                            clear
                                        </Button>
                                        <Snackbar
                                            open={this.state.open}
                                            autoHideDuration={4000}
                                            onClose={this.handleClose}
                                            TransitionComponent={this.state.Transition}
                                            message="wrong OTP code"
                                            key={this.state.Transition.name}
                                        />
                                    </div>

                                    <br />

                                    {this.state.step === 1 &&
                                        <div>
                                            <Typography
                                                className={classes.correctMobile}
                                                onClick={() => this.correctMobile()}
                                            >correct phone number</Typography>
                                        </div>
                                    }

                                </Grid>

                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {

    const isLogin = state.isLogin;

    return {
        isLogin
    }
}

// export default Login;

export default compose(
    withStyles(useStyles),
    connect(mapStateToProps)
)(Login)