import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as Playicon } from '../assets/icons/play.svg';
import TutorialVideo from './TutorialVideo';

const useStyles = makeStyles({
    root: {
        border: 0,
        borderRadius: 12,
        boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: 'white',
        // height: 95,
        // width: 290,
        // margin: '0 auto'
        display: 'flex',
        flexDirection: 'column',
        // backgroundColor: 'green',
        margin: 'auto'
    },
    leftSide: {
        backgroundImage: 'url("https://image.freepik.com/free-photo/young-man-woman-training-gym_368108-132.jpg")',
        // width: '50%',
        // float: 'left',
        height: '170px',
        // borderRadius: '12px 0px 0px 12px',
        position: 'relative',
        backgroundSize: '100%',
        borderTopRightRadius: '12px',
        borderTopLeftRadius: '12px',
    },
    rightSide: {
        // width: '45%',
        height: 'inherit',
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '18px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        paddingLeft: '5%',
    },
    playButton: {
        width: '52px',
        height: '52px',
        backgroundColor: '#F11F51',
        borderRadius: '50%',
        mixBlendMode: 'normal',
        opacity: 0.8,
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    playIcon: {
        width: '14px',
        height: '21px',
        borderRadius: '50%',
        mixBlendMode: 'normal',
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        color: 'white',
        transform: 'translate(-50%, -50%)'
    }
});

export default function IntroductionVideo() {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const openVideoDialog = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    return (
        <React.Fragment>
            <div
                className={classes.root}
                onClick={() => openVideoDialog()}
            >
                {/* <div>image</div>
                <div>desc</div> */}
                <div className={classes.leftSide}>
                    <div className={classes.playButton}></div>
                    <div className={classes.playIcon}>
                        <Playicon
                            style={{ fill: 'white', width: '15px', height: '15px' }} />
                    </div>
                </div>
                <div className={classes.rightSide}>Create exciting program in 10 minutes</div>
            </div>
            <TutorialVideo
                open={open}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
}