import { createStore, applyMiddleware, compose } from 'redux';
import reducer from "./reducer";
import createSagaMiddleware from 'redux-saga';
import { watchClinetToken } from '../saga/saga';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
window.store = store;

// here we must run SAGA for applications 
sagaMiddleware.run(watchClinetToken);

export default store;