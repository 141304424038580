import React from 'react';

class TimeDuration extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    state = {
        minutes: 0,
        seconds: 0
    }

    convertTo2DigitNumber = (rawNumber) => {
        let formattedNumber = rawNumber.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        return formattedNumber;
    }

    static getDerivedStateFromProps(props, state) {
        let minutes = Math.floor(props.duration / 60);
        let seconds = props.duration - minutes * 60;
        return {
            minutes: minutes,
            seconds: seconds
        };
    }

    reArrangeDuration = (event) => {
        this.props.reArrangeDuration(event.target.value);
    }

    incremenetSecondValue = (event) => {
        let duration = this.state.minutes * 60 + this.state.seconds;
        switch (event.keyCode) {
            case 38:
                this.props.reArrangeDuration(parseInt(duration) + 1);
                break;
            case 40:
                this.props.reArrangeDuration(parseInt(duration) - 1);
                break;
        }
        if (event.keyCode === 38) {
            this.props.reArrangeDuration(parseInt(duration) + 1);
        }
    }

    incremenetMinuteValue = (event) => {
        let duration = this.state.minutes * 60 + this.state.seconds;
        switch (event.keyCode) {
            case 38:
                this.props.reArrangeDuration(parseInt(duration) + 60);
                break;
            case 40:
                this.props.reArrangeDuration(parseInt(duration) - 60);
                break;
        }
        if (event.keyCode === 38) {
            this.props.reArrangeDuration(parseInt(duration) + 60);
        }
    }

    render() {
        const classes = {
            input: {
                width: '34px',
                marginRight: '5px',
                marginLeft: '5px',
                borderStyle: 'none',
                fontSize: '30px',
                '&:focus': {
                    outline: 'none'
                }
            }
        }

        return (
            <div
                style={{
                    border: '1px solid #121212',
                    borderRadius: '10px',
                    width: '119px',
                    height: '54px',
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative'
                }}
            >
                <input
                    type="text"
                    value={this.convertTo2DigitNumber(this.state.minutes)}
                    style={classes.input}
                    onChange={(e) => this.reArrangeDuration(e)}
                    onKeyUp={(e) => this.incremenetMinuteValue(e)}
                />
                <div
                    style={{
                        top: '10px',
                        position: 'absolute',
                        fontSize: '20px',
                        fontWeight: 'bold'
                    }}
                >:</div>
                <input
                    style={classes.input}
                    type="text"
                    value={this.convertTo2DigitNumber(this.state.seconds)}
                    onChange={(e) => this.reArrangeDuration(e)}
                    onKeyUp={(e) => this.incremenetSecondValue(e)}
                />
            </div>
        )
    }
}

export default TimeDuration;