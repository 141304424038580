import React from 'react';
import CheckBox from './CheckBox';
import { GET } from '../helpers/DataProvider';
import { Typography } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class CategoriesList extends React.Component { 
    constructor(props) {
        super(props);
    }

    state = {
        categoriesList: [],
        selectedCategoriesList: []
    }

    handleChange = (event, option) => {
        let index = -1;
        let categoriesList = this.state.categoriesList;
        for (let i = 0; i < categoriesList; i++) {
            if (categoriesList[i].id == option.id) {
                index = i
            }
        }
        if (index == -1) {
            categoriesList.push(option);
        } else {
            categoriesList.splice(index, 1);
        }
        this.setState({
            selectedCategoriesList: categoriesList
        })
    }

    getData = async () => {
        const categoriesResult = await GET(this.props.type, null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        this.setState({
            categoriesList: categoriesResult
        });
    }
    componentDidMount() {
        this.getData()
    }
    render() {
        return (
            <>
                <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={this.state.categoriesList}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option.title}
                    limitTags={3}
                    onChange={(event, newValue) => {
                        this.props.onCategoriesChange(newValue)
                    }}
                    defaultValue={this.props.value}
                    renderOption={(props, option, { selected }) => {
                        return (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.title}
                            </li>
                        )
                    }
                    }
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={this.props.label}
                            placeholder="add category"
                        />
                    )}
                />
            </>
        );
    }
}

export default CategoriesList;
