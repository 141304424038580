import React from 'react';
import Paper from '@material-ui/core/Paper';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from './assets/icons/trash-2.svg';
import { ReactComponent as ExpandIcon } from './assets/icons/chevron-down.svg';
import { ReactComponent as CollapseIcon } from './assets/icons/chevron-up.svg';
import { ReactComponent as ExpandsIcon } from './assets/icons/chevrons-down.svg';
import { ReactComponent as CollapsesIcon } from './assets/icons/chevrons-up.svg';
import Collapse from '@mui/material/Collapse';
import Hidden from '@material-ui/core/Hidden';
import { Typography } from '@material-ui/core';
import TextFieldWithCounter from './components/TextFieldWithCounter';
import DescriptionTextfiled from './components/DescriptionTextfiled';
import UploadBox from './components/UploadBox';
import { VideoEditor } from './components/VideoEditor';
import AddTechniquesInMobile from './AddTechniquesInMobile';
import AddTechnique from './AddTechnique';

type Props = {
    item: any,
    index: any,
    sessions: any,
    collapseSession: any,
    collapseSessions: any,
    setSessionTitle: any,
    setSessionDescription: any,
    uploadSessionVideo: any,
    openDeleteSessionDialog: any,
    removeSessionVideo: any,
    reArrangeDurations: any,
    addTechniquBar: any,
    setVideoProperties: any,
    addTechniqueName: any,
    addTechniqueRepetitionCount: any,
    toggleHasMusic: any,
    removeTechnique: any,
    updateDuration: any,
    uploadSessoinSoundtrack: any,
    uploadSessionCover: any,
    removeSessionCover: any,
    removeSessionSoundTrack: any,
    setSessionCalories: any
};

const DragHandle = SortableHandle(() => <span style={{
    position: 'absolute',
    backgroundColor: 'rgb(158 208 255)',
    height: '100%',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    borderTopLeftRadius: '7px',
    borderBottomLeftRadius: '7px',
    marginLeft: '0px',
    width: '7px',
    color: 'white'
}}>::</span>);

const SortableItem: any = SortableElement(({ value }: any) => <li style={{ listStyle: 'none', position: 'relative' }}><DragHandle />{value}</li>);

function session({
    item,
    index,
    sessions,
    collapseSession,
    collapseSessions,
    openDeleteSessionDialog,
    setSessionTitle,
    setSessionDescription,
    uploadSessionVideo,
    removeSessionVideo,
    reArrangeDurations,
    addTechniquBar,
    setVideoProperties,
    addTechniqueName,
    addTechniqueRepetitionCount,
    toggleHasMusic,
    removeTechnique,
    updateDuration,
    uploadSessoinSoundtrack,
    removeSessionSoundTrack,
    setSessionCalories,
    uploadSessionCover,
    removeSessionCover
}: Props) {
    return (

        <React.Fragment
        // key={{ index }}
        >
            <SortableItem
                key={item.sessionNo}
                index={index}
                value={
                    <Paper
                        style={{
                            padding: '8px 20px 0px',
                            borderRadius: 12
                        }}
                    >

                        <Collapse
                            orientation="vertical"
                            in={sessions[index].isCollapse}
                            collapsedSize={44}
                        >
                            <div
                                style={{
                                    // margin: '4% 0'
                                }}
                            >

                                {/* <Hidden only={['xs']}> */}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: 'Mulish',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: 24,
                                            color: '#121212',
                                        }}
                                    >
                                        {item.title ? item.title : `Session ${index + 1}`}
                                    </Typography>
                                    <div>
                                        <IconButton
                                            aria-label="close"
                                            color="primary"
                                            // sx={{ p: 0.5 }}
                                            style={{
                                                color: 'black',
                                                width: "30px",
                                                height: "30px"
                                            }}
                                            onClick={(event) => collapseSession(event, index)}
                                        >
                                            {sessions[index].isCollapse ? <CollapseIcon /> : <ExpandIcon />}
                                        </IconButton>

                                        <IconButton
                                            aria-label="close"
                                            // sx={{ p: 0.5 }}
                                            style={{
                                                color: 'black',
                                                width: "30px",
                                                height: "30px"
                                            }}
                                            onClick={() => openDeleteSessionDialog(index)}
                                            color="primary"
                                            title="remove session"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <br />
                                <Typography
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        fontSize: '20px',
                                        lineHeight: '24px',
                                        color: '#121212'
                                    }}
                                >
                                    Choose proper name
                                </Typography>
                                {/* </Hidden> */}
                                {/* <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Mulish',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            lineHeight: '28px',
                                            color: '#121212'
                                        }}
                                    >
                                        Choose proper name for the session
                                    </Typography>
                                </Hidden> */}
                            </div>


                            <div
                                style={{
                                    margin: '4% 0'
                                }}
                            >
                                <TextFieldWithCounter
                                    value={sessions[index].title}
                                    onChange={(event: any) => setSessionTitle(event.target.value, index)}
                                />
                            </div>

                            <div
                                style={{
                                    margin: '4% 0'
                                }}
                            >
                                <Typography
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: 'normal',
                                        fontSize: '20px',
                                        lineHeight: '24px',
                                        color: '#121212'
                                    }}
                                >
                                    Describe the workout session content and requirements
                                </Typography>
                            </div>

                            <div>
                                <DescriptionTextfiled
                                    value={sessions[index].description}
                                    onChange={(event: any) => setSessionDescription(event.target.value, index)}
                                />
                            </div>

                            <div
                                style={{
                                    margin: '4% 0'
                                }}
                            >
                                {/* <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                    <Typography
                                        style={{
                                            fontFamily: 'Mulish',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            fontSize: '18px',
                                            lineHeight: '28px',
                                            color: '#121212',
                                        }}
                                    >
                                        Uplaod your session primary video here
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontFamily: 'Mulish',
                                            fontStyle: 'normal',
                                            fontWeight: 'normal',
                                            fontSize: '16px',
                                            lineHeight: '24px',
                                            color: '#121212',
                                        }}
                                    >
                                        The video size should be less than 100MB
                                    </Typography>
                                </Hidden> */}
                                {!item.mediaId.length ?
                                    <UploadBox
                                        label={"Upload or drag your video here"}
                                        onUpload={(uploadResult: any) => uploadSessionVideo(uploadResult, index)}
                                        type="SESSION-VIDEO"
                                        accept=".mp4"
                                        onRemove={() => removeSessionVideo(index)}
                                        source={item.sessionVideoDownloadPath}
                                    />
                                    : null
                                }

                                <br />

                                {item.mediaId.length ?
                                    <VideoEditor
                                        addTechniquBar={(segmentsArr: any) => addTechniquBar(segmentsArr, index)}
                                        segments={item.techniques}
                                        removeSessionVideo={() => removeSessionVideo(index)}
                                        setVideoProperties={(e: any) => setVideoProperties(e, index)}
                                        downloadPath={item.downloadPath}
                                        duration={item.mediaDuration}
                                        sessionIndex={index}
                                        reArrangeDurations={(newSegments: any) => reArrangeDurations(newSegments, index)}
                                    />
                                    : null
                                }

                            </div>

                            {/* <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <AddTechniquesInMobile

                                />
                            </Hidden> */}

                            {/* <Hidden only={['xs']}> */}
                            <AddTechnique
                                sessions={sessions}
                                index={index}
                                addTechniqueName={(val: any, techniqueIndex: any) => addTechniqueName(val, techniqueIndex, index)}
                                addTechniqueRepetitionCount={(val: any, techniqueIndex: any) => addTechniqueRepetitionCount(val, techniqueIndex, index)}
                                toggleHasMusic={(val: any, techniqueIndex: any) => toggleHasMusic(val, techniqueIndex, index)}
                                removeTechnique={(techniqueIndex: any) => removeTechnique(techniqueIndex, index)}
                                reArrangeDuration={(newDuration: any, techniqueIndex: any) => updateDuration(newDuration, techniqueIndex, index)}
                            />
                            <UploadBox
                                label={"Upload or drag your music soundtrack"}
                                onUpload={(uploadResult: any) => uploadSessoinSoundtrack(uploadResult, index)}
                                onRemove={() => removeSessionSoundTrack(index)}
                                source={item.sessionSoundTrackDownloadPath}
                                type="SESSION-AUDIO"
                                accept=".mp3"
                            />

                            <input
                                type="number"
                                value={sessions[index].calories}
                                // onKeyUp={(e) => this.incremenetSecondValue(e)}
                                onChange={(event) => setSessionCalories(event.target.value, index)}
                            />

                            <UploadBox
                                label={"Upload or drag your session cover"}
                                onUpload={(uploadResult: any) => uploadSessionCover(uploadResult, index)}
                                onRemove={() => removeSessionCover(index)}
                                source={item.coverDownloadPath}
                                type="SESSION-COVER"
                                accept=".jpg,.bmp,.png,.gif"
                            />

                            <br />
                            {/* </Hidden> */}
                        </Collapse>
                    </Paper>
                }
            >

            </SortableItem>

            <br />
        </React.Fragment>
    )
}

export default session