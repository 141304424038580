import React from 'react';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { ReactComponent as Closeicon } from '../assets/icons/x.svg';
import { GET, POST } from '../helpers/DataProvider';
import Typography from '@mui/material/Typography';
import { withStyles } from '@material-ui/core/styles';
import store from '../redux/store';
import { setCategories } from '../redux/actions';

const useStyles = (theme) => ({
    tag: {
        marginRight: '5px',
        minWidth: '20px',
        height: '34px',
        background: '#E0F1FF',
        borderRadius: '6px',
        display: 'inline-flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: '2px'
    },
    tagLabel: {
        fontSize: '14px',
        fontFamily: 'Quicksand',
        color: '#182A7D',
        margin: '5px'
    },
    tagButton: {
        color: '#182A7D',
        cursor: 'pointer',
        height: '24px',
        margin: '5px'
    }
});

class Categories extends React.Component {

    constructor(props) {
        super(props);
        this.selectInputRef = React.createRef();
    }

    state = {
        selectedCategories: [],
        categories: [],
        isDirty: false
    }

    ifTagExists = (tagItem) => {
        return this.state.categories.findIndex(tag => tagItem.label === tag.label) > 0 ? true : false;
    }

    onChangeCreate = (tag) => {
        this.handleChange(tag);
        this.selectInputRef.current.removeValue();
    }

    getCategories = async () => {
        const resultCategories = await GET("category", null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        let tempObj = {};
        let bakedTags = [];
        resultCategories.map(
            (item, index) => {
                tempObj = {};
                tempObj.id = item._id;
                tempObj.value = item.title;
                tempObj.label = item.title;
                tempObj.color = '#00B8D9';
                tempObj.isFixed = true;
                bakedTags.push(tempObj);
            }
        )
        this.setState({
            categories: bakedTags
        }, () => {
            store.dispatch(setCategories(this.state.categories));
        });
    }

    componentDidMount = async () => {

        await this.getCategories();
        let sc = [];
        for (let i = 0; i < this.props.value.length; i++) {
            for (let j = 0; j < this.state.categories.length; j++) {
                if (this.props.value[i] == this.state.categories[j].id
                    ||
                    this.props.value[i].id == this.state.categories[j].id
                ) {
                    sc.push(this.state.categories[j]);
                }
            }
        }
        this.setState({
            selectedCategories: sc
        }, () => {
            if (this.state.selectedCategories.length > 0) {
                this.setState({
                    isDirty: true
                })
            }
            let newCategories = this.state.categories;
            for (let st of this.state.selectedCategories) {
                let index = this.state.categories.findIndex(tag => tag.id === st.id);
                newCategories.splice(index, 1);
            }
            this.setState({
                categories: newCategories
            })
        });
    }

    // componentDidUpdate = async (prevProps, prevState, snapshot) => {
    //     if (this.state.selectedCategories != prevProps.value || this.state.selectedCategories != prevState.selectedCategories) {
    //         this.setState({
    //             selectedCategories: prevProps.value
    //         });
    //     }
    //     if ((this.state.selectedCategories != prevProps.value || this.state.selectedCategories != prevState.selectedCategories) && prevProps.value.length == 0) {
    //         this.getCategories()
    //     }
    // }

    handleChange = (item) => {
        this.setState({
            isDirty: true
        })
        let ls = this.state.selectedCategories;
        let flag = false;
        for (let l of ls) {
            if (l.value === item.value) {
                flag = true
            }
        }

        if (!flag) {
            ls.push(item);
        }

        this.setState({
            selectTag: ls
        }, () => {
            let tempArr = [];
            ls.forEach((item, index) => {
                tempArr.push(item);
            });
            this.props.onChange(tempArr);

            // remove from tags
            let newtags = this.state.categories;
            let index = this.state.categories.findIndex(obj => obj.id === item.id);
            newtags.splice(index, 1);
        });
    }

    removeItem = (item) => {
        let ls = this.state.selectedCategories;
        let newLs = [];
        for (let st of ls) {
            if (st.value !== item.value) {
                newLs.push(st)
            }
        }
        let allCategories = this.state.categories;
        allCategories.push(item);
        this.setState({
            selectedCategories: newLs,
            categories: allCategories
        }, () => {
            this.props.onChange(this.state.selectedCategories);
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                style={{
                    minWidth: '65%'
                }}
            >
                <div>
                    <Select
                        ref={this.selectInputRef}
                        isClearable
                        onChange={(item) => this.onChangeCreate(item)}
                        options={this.state.categories}
                        placeholder={'type your category'}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        marginTop: '5px',
                        flexWrap: 'wrap'
                    }}
                >

                    {
                        this.state.selectedCategories.map(
                            (selectCategory, index) =>
                                <div
                                    key={index}
                                    className={classes.tag}
                                >
                                    <div
                                        className={classes.tagLabel}>
                                        {selectCategory.label}
                                    </div>

                                    <div
                                        className={classes.tagButton}
                                        onClick={() => this.removeItem(selectCategory)}
                                    >
                                        <Closeicon />
                                    </div>

                                </div>
                        )}
                </div>
                {this.state.isDirty && this.state.selectedCategories.length == 0 && <Typography
                    style={{
                        color: '#f44336',
                        marginTop: 2,
                        marginLeft: 4,
                    }}
                >1 category at least</Typography>}
            </div>
        )
    }

}

export default withStyles(useStyles)(Categories);