import React from 'react';
import store from './redux/store';
import { Provider } from 'react-redux';
import { withRouter } from "react-router-dom";
import MainContainer from './layouts/MainContainer';
import { setUserLastLocation } from './redux/actions';

class App extends React.Component {

  componentDidMount() {
    window.location.href = '#/check-status'
  }

  componentWillMount() {
    this.props.history.listen((location, action) => {
      if (location.pathname !== '/check-status')
        window.localStorage.setItem('last-location', '#' + location.pathname);
      //  if (location.pathname === '/new-program') {
      //    if (localStorage.getItem('creationMode') === 'edit') {
      //      alert('Are You sure');
      //    }
      //  }
      store.dispatch(setUserLastLocation(location));
    });
  }

  render() {
    return (
      <Provider store={store}>
        <MainContainer />
      </Provider>
    );
  }

}

export default withRouter(App);