import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import { ReactComponent as UploadCloud } from '../assets/icons/upload-cloud.svg';
import { Hidden } from '@material-ui/core';
import { ReactComponent as Closeicon } from '../assets/icons/x.svg';
import CustomizedProgressBars from './CustomizedProgressBars';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import CancelUploadDialog from '../dialogs/CancelUploadDialog';
import DisplayImageOrSound from '../DisplayImageOrSound';

const useStyles = makeStyles({
    button: {
        backgroundColor: '#182A7D',
    }
})

function UploadBox(props) {
    let requestObject = new XMLHttpRequest();
    const classes = useStyles();
    const fileOpenRef = React.createRef(null);
    const [isUploading, setIsUploading] = React.useState(props.source === null || props.source === undefined ? null : false);
    const [fileName, setFileName] = React.useState(props.source != null ? props.source.split('/')[props.source.split('/').length - 1] : null);
    const [percent, setPercent] = React.useState(0);
    const [showCancelUploadDialog, setShowCancelUploadDialog] = React.useState(false);
    const [active, setActive] = React.useState(false);
    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
    });
    let acceptList;
    let uploadAddress;
    switch (props.type) {
        case 'PROGRAM-PHOTO':
            acceptList = '.jpg,.bmp,.png,.gif';
            uploadAddress = process.env.REACT_APP_API_URL + 'upload/programs/photo';
            break;
        case 'SESSION-VIDEO':
            acceptList = '.mp4';
            uploadAddress = process.env.REACT_APP_API_URL + 'upload/sessions/video';
            break;
        case 'SESSION-AUDIO':
            acceptList = '.mp3';
            uploadAddress = process.env.REACT_APP_API_URL + 'upload/sessions/audio';
            break;
        case 'SESSION-COVER':
            acceptList = '.jpg,.bmp,.png,.gif';
            uploadAddress = process.env.REACT_APP_API_URL + 'upload/sessions/cover';
            break;
        default:
            acceptList = '.jpg,.mp4';
    }

    const Decider = ({ source }) => {
        if (isUploading === false && source !== null)
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        height: 50,
                        alignItems: 'center',
                        backgroundColor: '#ced4da',
                        padding: '0px 12px',
                        border: '1px solid #bdbdbd'
                    }}
                >
                    <div
                        style={{
                            height: 40,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <DisplayImageOrSound
                            type={props.type}
                            accept={props.accept}
                            fileName={fileName}
                            source={source}
                        />

                    </div>
                    <div
                        style={{
                            cursor: 'pointer'
                        }}
                        onClick={() => removeFile()}
                    >
                        <Closeicon />
                    </div>
                </div>


            );
        if (isUploading === true) {
            return (
                <div
                    style={{
                        display: 'flex'
                    }}
                >

                    <div
                        style={{
                            display: 'flex',
                            width: '100%',
                        }}
                    >
                        <CustomizedProgressBars
                            value={percent}
                            max="100"
                        />
                        <div
                            style={{
                                margin: 'auto auto',
                            }}
                        >{percent}%</div>
                    </div>

                    <Tooltip title="cancel upload">
                        <IconButton aria-label="delete">
                            <ClearIcon
                                onClick={() => displayCancelUploadDialog()}
                            />
                        </IconButton>
                    </Tooltip>
                </div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    const handleClose = () => {
        setIsUploading(null);
        setState({ open: false });
    };

    const openDialog = () => {
        fileOpenRef.current.click();
    }

    const uploadFile = async (file) => {
        setIsUploading(true);
        let fileInfos = file || fileOpenRef.current.files[0];
        let data = new FormData();
        data.append('mediaFile', fileInfos);
        requestObject.open('POST', uploadAddress);
        requestObject.setRequestHeader('X-ACCESS-TOKEN', window.localStorage.getItem('token'));

        // upload progress event
        requestObject.upload.addEventListener('progress', (e) => {
            let percent_completed = (e.loaded / e.total) * 100;
            setPercent(parseInt(percent_completed));
        });

        // request finished event
        requestObject.addEventListener('load', function (e) {
            setFileName(fileInfos.name);
            props.onUpload(JSON.parse(requestObject.response).data);
            setIsUploading(false);
        });

        requestObject.send(data);
        fileOpenRef.current.value = null;
    }

    const displayCancelUploadDialog = () => {
        setShowCancelUploadDialog(true);
    }

    const abortUpload = () => {
        requestObject.abort();
        setClose();
        rollbackUpload();
    }

    const rollbackUpload = () => {
        new Promise((resolve, reject) => {
            setPercent(0);
            setTimeout(() => resolve("done"), 1000);
        })
            .then(
                result => {
                    setIsUploading(null);
                }
            )
    }

    const setClose = () => {
        setShowCancelUploadDialog(false);
    }

    const removeFile = () => {
        handleClose();
        props.onRemove();
    }

    const dropHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            uploadFile(event.dataTransfer.files[0]);
        }
        setActive(false);
    }

    const dragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setActive(true);
    }

    const dragEnd = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setActive(false);
    }

    return (
        <div
            // className={`${classes.uploadBoxContainer} ${active ? classes.uploadBoxContainerActive : ''}`}
            className={classes.uploadBoxContainer}
            onDrop={(event) => dropHandler(event)}
            onDragOver={(event) => dragOver(event)}
            onDragLeave={(event) => dragEnd(event)}
            style={{
                border: `2px dashed grey`,
                borderRadius: '10px',
                height: 150,
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'flex-start'
            }}
        >
            <div
                style={{
                    paddingLeft: (props.width === 'xs') ? 0 : '24px'
                }}
            >
                <Hidden only={['xs']}>
                    <div
                        style={{
                            margin: '10px 0px'
                        }}
                    >
                        {props.label}
                    </div>
                </Hidden>
                <div
                    style={{
                        margin: '10px 0px'
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        // className={classes.button}
                        startIcon={<UploadCloud />}
                        onClick={() => openDialog()}
                        disabled={isUploading}
                    >
                        Upload
                    </Button>
                    <input
                        type="file"
                        accept={acceptList}
                        ref={fileOpenRef}
                        onChange={() => uploadFile()}
                        style={{
                            width: 0
                        }}
                    />

                </div>

            </div>
            <div
                style={{
                    width: '100%',
                }}
            >
                <Decider
                    isUploading={isUploading}
                    source={props.source}
                />

            </div>

            {showCancelUploadDialog &&
                <CancelUploadDialog
                    open={showCancelUploadDialog}
                    setClose={() => setClose()}
                    ignoreUpload={() => abortUpload()}
                />
            }

        </div >
    )
}

export default UploadBox;
