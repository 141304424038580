import React from 'react';
import { ReactComponent as PlussquareIcon } from './assets/icons/plus-square.svg';

class AddSession extends React.Component {

    state = {
        value: 0,
    }

    incrementValue = () => {
        this.setState({
            val: this.state.value++
        })
    } 

    addSession = () => {
        this.props.onClick();
    }

    decrementValue = () => {
        this.setState({
            value: this.state.value--
        })
    }

    render() {


        return (
            <div>


                <div
                    style={{
                        // display: 'flex'
                    }}
                    onClick={() => this.addSession()}
                >

                    <div
                        style={{
                            background: 'rgba(210, 210, 210, 0.100634)',
                            border: '1px solid rgba(210, 210, 210, 0.4)',
                            borderRadius: '12px',
                            cursor: 'pointer',
                            height: '76px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '90%',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <PlussquareIcon
                                    style={{
                                        color: '#878787',
                                        width: 28,
                                        height: 28
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Mulish',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: '#434343',
                                    paddingLeft: 10
                                }}
                            >
                                Add session
                            </div>
                        </div>
                    </div>


                </div>

            </div >
        )
    }
}

export default AddSession;