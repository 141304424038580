import React from 'react';
import { ReactComponent as PlusIcon } from './assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from './assets/icons/minus.svg';

class Counter extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    state = {
        repetitionCount: this.props.value
    }

    componentDidMount(props) {
        this.setState({
            repetitionCount: this.props.value
        })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            repetitionCount: nextProps.value
        })
    }

    incrementValue = () => {
        this.setState({
            repetitionCount: ++this.state.repetitionCount
        }, () => {
            this.props.updateRepetitionCount(this.state.repetitionCount)
        })
    }

    decrementValue = () => {
        if (this.state.repetitionCount > 1) {
            this.setState({
                repetitionCount: --this.state.repetitionCount
            }, () => {
                this.props.updateRepetitionCount(this.state.repetitionCount)
            })
        }
    }

    render() {
        const classes = {
            appBar: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0'
            },
            input: {
                width: '34px',
                marginRight: '5px',
                marginLeft: '5px',
                borderStyle: 'none',
                fontSize: '30px',
                '&:focus': {
                    outline: 'none'
                }
            },
            numberOfRepetitions: {
                border: '1px solid #121212',
                borderRadius: '10px',
                width: '162px',
                height: '54px',
                display: 'flex',
                justifyContent: 'center',

            },
            numberOfRepetitionsItem: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '24px',
                lineHeight: '30px',
                color: '#121212'
            },
            minusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderLeftStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#D0D5DA',
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
            },
            plusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#D0D5DA',
                borderBottomRightRadius: 10,
                borderTopRightRadius: 10
            }
        }

        return (
            <div
                style={classes.numberOfRepetitions}
            >

                <div
                    style={classes.minusOperator}
                    onClick={() => this.decrementValue()}
                >
                    <MinusIcon />
                </div>

                <div
                    style={classes.numberOfRepetitionsItem}
                >
                    {this.state.repetitionCount}
                </div>

                <div
                    style={classes.plusOperator}
                    onClick={() => this.incrementValue()}
                >
                    <PlusIcon />
                </div>
            </div>
        )
    }
}

export default Counter;