import React, { ReactNode, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { compose } from "redux";
import reducer from "../../redux/reducer";

const MyButton = styled(Button)({
    background: '#FFFFFF',
    border: 0,
    borderRadius: 10,
    color: '#182A7D',
    height: 76,
    padding: '0 30px',
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Mulish',
    fontWeight: 700,
    width: '194px',
    margin: '8px 0',
    textTransform: 'none',
    justifyContent: 'left',
    '&:hover': {
        backgroundColor: '#E0F1FF',
    },
});

const useStyles = makeStyles((theme) => ({
    highLight: {
        backgroundColor: '#E0F1FF',
    }
}));

interface ISidebarButton {
    href: string,
    icon: string,
    lastLocation: string,
    children: ReactNode
}

const SidebarButton: React.FC<ISidebarButton> = (props) => {
    const { href, icon, lastLocation, children } = props;
    const classes = useStyles();
    return (
        <Link
            to={href}
            component={RouterLink}
            style={{
                textDecoration: 'none'
            }}
        >
            <MyButton
                startIcon={icon}
                className={href == lastLocation ? classes.highLight : ''}
            >
                {children}
            </MyButton>
        </Link>
    )
}

type RootState = {
    lastLocation: {
        pathname: string;
    };
};

const mapStateToProps = (state: RootState) => {
    const lastLocation = state.lastLocation.pathname;
    return {
        lastLocation
    }
}

export default compose(
    connect(mapStateToProps)
)(SidebarButton)