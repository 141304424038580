import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import NewProgramButton from '../components/buttons/NewProgramButton';
import { ReactComponent as PlussquareIcon } from '../assets/icons/plus-square.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        maxWidth: '500px',
        margin: '35px auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: '#fdfdfd',
        borderRadius: '3px',
        // border: '1px solid #d1d1d1',
    },
    image: {
        width: 300
    },
}));
function NoProgram() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <div
                style={{
                    fontSize: 24
                }}
            >you didn't create program yet</div>
            <div
                style={{
                    fontSize: 14,
                    marginBottom: 34
                }}
            >click to create your first program</div>
            <NewProgramButton
                icon={<PlussquareIcon />}
                href={`/new-program`}
            />
        </div>
    )
}
export default NoProgram;