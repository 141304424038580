import {
    getUserInformation
} from "../redux/actions";
import {
    FETCHED_CLIENT_TOKEN,
    FETCH_USER_INFORMATION
} from "../redux/constants";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { GET } from "../helpers/DataProvider";

/**
 * Watching for client token
 */
function* watchClinetToken() {
    yield takeEvery(FETCHED_CLIENT_TOKEN, fetchClientToken);
}

function* fetchClientToken() {
    const data = yield select();
    yield put(getUserInformation(data));
}


/**
 * Watching for user information
 */
function* watchUserInformation() {
    yield takeEvery(FETCH_USER_INFORMATION, fetchUserInformation);
}

function* fetchUserInformation() {
    try {
        const data = yield call(() => {
            return GET("profile", null, null, null, 'AuthenticationApi');
        });
        
    } catch (error) {
        
    }
}

export {
    watchClinetToken,
    watchUserInformation
};
