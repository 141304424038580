import React from 'react';
import TechniqueRow from './components/TechniqueRow';

class AddTechnique extends React.Component {

    state = {
        value: 0,
    }

    incrementValue = () => {
        this.setState({
            val: this.state.value++
        })
    }

    AddTechnique = () => {
        this.setState({
            addTechniqueRowCount: this.state.addTechniqueRowCount.push({ row: 1 })
        });
    }

    decrementValue = () => {
        this.setState({
            value: this.state.value--
        })
    }

    addTechniqueName = (val, techniqueIndex) => {
        this.props.addTechniqueName(val, techniqueIndex);
    }

    toggleHasMusic = (val, techniqueIndex) => {
        this.props.toggleHasMusic(val, techniqueIndex);
    }

    addTechniqueRepetitionCount = (val, techniqueRepetitionCountIndex) => {
        this.props.addTechniqueRepetitionCount(val, techniqueRepetitionCountIndex);
    }

    removeTechnique = (techniqueIndex) => {
        this.props.removeTechnique(techniqueIndex);
    }

    render() {

        return (
            <div>
                <div
                    style={{
                        fontFamily: 'Mulish',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: 20,
                        color: '#121212'
                    }}
                >Specify training details </div>

                <br />
                {this.props.sessions[this.props.index].techniques.map(
                    (item, index) =>
                        !item.isDeleted && (<TechniqueRow
                            item={item}
                            toggleHasMusic={(val) => this.toggleHasMusic(val, index)}
                            addTechniqueName={(val) => this.addTechniqueName(val, index)}
                            addTechniqueRepetitionCount={(val) => this.addTechniqueRepetitionCount(val, index)}
                            removeTechnique={() => this.removeTechnique(index)}
                            reArrangeDuration={(newDuration) => this.props.reArrangeDuration(newDuration, index)}
                        />)
                )}
            </div >
        )
    }
}

export default AddTechnique;