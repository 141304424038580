import React from "react";
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    container: {
        // maxWidth: '500px',
        margin: '35px auto',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        // backgroundColor: '#fdfdfd',
        // borderRadius: '3px',
        // border: '1px solid #d1d1d1',
    },
    image: {
        width: 300
    },
}));
function UnderConstruct() {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img
                src={process.env.PUBLIC_URL + 'images/pixeltrue-icons-building-website-3.png'}
                className={classes.image}
            />
            <div
                style={{
                    fontSize: 24
                }}
            >Under Construction</div>
        </div>
    )
}
export default UnderConstruct;